<template>
	<div class="historyTable">
		<div class="historyTable_content">
			<div class="historyTable_list">
				<div class="historyTable_list_box">
					<el-table class="historyTable_list_inner" :header-cell-style="headStyle" :cell-style="rowStyle"
						:data="historyData" :border="true">
						<el-table-column width="150px" prop="createTime" label="发放时间">
						</el-table-column>
						<el-table-column prop="createUserName" label="发放人">
						</el-table-column>
						<el-table-column prop="title" label="任务标题">
						</el-table-column>
						<el-table-column prop="content" label="任务内容">
							<template slot-scope="scope">
								<el-tooltip popper-class="tooltip_bg" class="item" effect="dark"
									:content="scope.row.content" placement="top-start" v-if="scope.row.content.length>30">
									<div class="knowledgeBase_table_introduction">
										{{scope.row.content}}
									</div>
								</el-tooltip>
								<div v-else>{{scope.row.content}}</div>
							</template>
						</el-table-column>
						<el-table-column prop="file" label="任务附件">
							<template slot-scope="scope">
								<div v-if="scope.row.attPath" @click="downLoadFile(scope.row.attNamq, scope.row.attPath)"
									class="historyTable_slot_box">
									<span class="historyTable_slot_btn">
										<i style="margin-right: 4px;"
											class="el-icon-paperclip"></i>{{scope.row.attNamq?scope.row.attNamq:'下载'}}
									</span>
								</div>
							</template>
						</el-table-column>
						<el-table-column width="180px" prop="completeDateEnd" label="任务周期">
							<template slot-scope="scope">
								<div class="">
									{{formatDate(scope.row.completeDateStart)}}至{{formatDate(scope.row.completeDateEnd)}}
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="status" label="任务完成情况">
							<template slot-scope="scope">
								<div class="historyTable_slot_box">
									<span
										:class="{'slot_tag_green': scope.row.status == 2,'slot_tag_yellow': scope.row.status == 1, }"
										class="historyTable_slot_tag">{{scope.row.status == 1?'未完成': '已完成'}}</span>
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="mark" label="学生备注">
							<template slot-scope="scope">
								<div class="">
									{{scope.row.replyRemark}}
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="studentFile" label="学生附件">
							<template slot-scope="scope">
								<div class="historyTable_slot_box" v-if="scope.row.replyAtts&&scope.row.replyAtts.length > 0">
									<el-dropdown trigger="click">
										<div class="historyTable_slot_btn">
											<span @click="downLoadFile(scope.row.replyAtts[0].replyattNamq,scope.row.replyAtts[0].replyattPath)">{{scope.row.replyAtts[0].replyattNamq?scope.row.replyAtts[0].replyattNamq:'下载'}}</span><i v-if="scope.row.replyAtts.length > 1" class="el-icon-arrow-down el-icon--right"></i>
										</div>
										<el-dropdown-menu v-if="scope.row.replyAtts.length > 1" slot="dropdown">
											<el-dropdown-item v-for="(item,index) in scope.row.replyAtts.slice(1,scope.row.replyAtts.length)" :key="index" icon="el-icon-paperclip">
												<span @click="downLoadFile(item.replyattNamq,item.replyattPath)">{{item.replyattNamq?item.replyattNamq:'下载'}}</span>
											</el-dropdown-item>
										</el-dropdown-menu>
									</el-dropdown>
								</div>
							</template>
						</el-table-column>
					</el-table>
				</div>
			</div>
		</div>
		<div class="history_table_page">
			<el-pagination @current-change="currentChange" :current-page.sync="pageIndex" :page-size="pageSize"
				layout="total, prev, pager, next" :total="totalCount">
			</el-pagination>
		</div>
	</div>
</template>

<script>
	import commonFn from "@/utils/common.js";
	import {
		historyTaskGrant,
	} from "@/api/taskProgress";
	import {
		saveAs
	} from 'file-saver';
	export default {
		props: {
			studentId: {
				type: String,
				default: function() {
					return ""
				}
			}
		},
		data() {
			return {
				historyData: [],
				pageIndex: 1,
				pageSize: 12,
				totalCount: 0,

			}
		},
		methods: {
			headStyle() {
				return 'text-align:center;background: #F7F7F7;font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #666666;'
			},
			rowStyle() {
				return 'text-align:center;font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #333333;'
			},
			formatDate(intDate) {
				if (intDate) {
					return commonFn.timeFormat(intDate, "yyyy-MM-dd")
				} else {
					return ""
				}
			},
			downLoadFile(name, file) {
				let pointIndex = name.lastIndexOf('.')
				let fileName = ""
				let fileType = ""
				if (pointIndex != -1) {
					fileName = name.substring(0, pointIndex).toLowerCase()
					fileType = name.substring(pointIndex + 1, name.length).toLowerCase()
					// console.log("fileType", fileType)
				}
				if (fileType == 'txt') {
					let xhr = new XMLHttpRequest();
					xhr.open("get", file, true);
					xhr.responseType = "blob";
					xhr.onload = function() {
						if (this.status == 200) {
							const reader = new FileReader()
							reader.onload = function() {
								// console.log('reader.result', reader.result)
								var blob = new Blob([reader.result], {
									type: 'text/plain;charset=utf-8'
								})
								saveAs(blob, fileName + '.txt')
							}
							reader.readAsText(this.response);
						}
					};
					xhr.send();
				} else {
					window.open(file)
				}
			},
			// 获取任务发放记录
			taskGrantHistory() {
				let params = {
					current: this.pageIndex,
					studentInfoId: this.studentId,
					size: this.pageSize
				}
				historyTaskGrant(params).then((res) => {
					if (res.code == 0) {
						this.historyData = res.data.records
						this.totalCount = res.data.total * 1
					}
				})
			},
			currentChange(val) {
				this.pageIndex = val
				this.taskGrantHistory()
			},
		}
	}
</script>

<style lang="scss" scoped="">
	@import "../../../styles/index.scss";
	.knowledgeBase_table_introduction {
		line-height: 20px;
		font-size: 14px;
		font-family: Source Han Sans CN;
		font-weight: 400;
		color: #333333;
		overflow: hidden;
		cursor: pointer;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
	}
	// 自定义滚动条样式
	/deep/.historyTable_list ::-webkit-scrollbar {
		width: 3px;
		height: 15px;
	}

	/deep/.historyTable_list ::-webkit-scrollbar-thumb {
		//滑块部分
		background-color: rgba(91, 168, 151, 1);
	}

	.historyTable {
		width: 100%;
		display: flex;
		flex-direction: column;
		position: absolute;

		.historyTable_content {
			width: 100%;

			.historyTable_list {
				.historyTable_list_box {
					.historyTable_list_inner {

						// border-left: 1px solid #EEEEEE;
						// border-right: 1px solid #EEEEEE;
						.historyTable_slot_box {
							height: 60px;
							display: flex;
							align-items: center;
							justify-content: center;

							.historyTable_slot_btn {
								font-size: 14px;
								font-family: Source Han Sans CN;
								font-weight: 400;
								line-height: 20px;
								color: $theme_color;
								cursor: pointer;
							}

							.historyTable_slot_tag {
								line-height: 23px;
								border-radius: 2px;
								padding: 0 8px;
								text-align: center;
								font-size: 14px;
								font-family: Source Han Sans CN;
								font-weight: 400;

								&.slot_tag_green {
									background: #EEF6F4;
									color: $theme_color;
								}

								&.slot_tag_yellow {
									background: #FAF1EB;

									color: #D0743B;
								}
							}
						}
					}
				}
			}
		}
	}

	.history_table_page {
		text-align: center;
		margin-top: 20px;
	}
</style>
