<template>
	<el-dialog :modal="false" class="dialog_bg" :append-to-body="false" :modal-append-to-body="false" @close="closeDialog" width="579px" :title="diaLogTitle" :visible.sync="diaLogVisible">
		<div class="handle_form">
			<el-form ref="targetForm" :rules="targetRules" :model="targetForm" label-width="70px">
				<div class="handle_form_row">
					<div class="handle_form_item">
						<el-form-item class="flex_item" prop="iptVal" :label="diaLogTitle == '策略编辑'?'策略标题:':'分类标题'">
							<input v-model="targetForm.iptVal" class="handle_form_ipt" placeholder="请输入" />
						</el-form-item>
					</div>
				</div>
			</el-form>
			<div class="handle_form_btn">
				<span @click="closeDialog" class="handle_form_cancel">取消</span>
				<span v-preventReClick @click="submitDialog" class="handle_form_confirm">确定</span>
			</div>
		</div>
	</el-dialog>
</template>

<script>
	import {
		addAllViewsClassify,
		editAllViewsClassify,
		editAllViewsStrategy,
		addAllViewsStrategy,
	} from "@/api/taskProgress"
	import Bus from "./eventBus.js";
	export default {
		data() {
			const checkIptVal = (rule, value, callback) => {
				if (!value) {
					if (this.diaLogTitle == "分类新增") {
						callback(new Error("请输入分类标题"));
					} else if (this.diaLogTitle == "分类编辑") {
						callback(new Error("请输入分类标题"));
					} else if (this.diaLogTitle == "策略编辑") {
						callback(new Error("请输入策略标题"));
					} else if (this.diaLogTitle == "策略新增") {
						callback(new Error("请输入策略标题"));
					}
				} else {
					callback();
				}
				callback();
			};
			return {
				diaLogTitle: "",
				diaLogVisible: false,
				targetForm: {},
				targetRules: {
					iptVal: [{
						required: true,
						validator: checkIptVal,
						trigger: 'change'
					}],
				},
				propsData: {},
				handleType: ""
			}
		},
		mounted() {
			Bus.$on("allviewTableHandle", props => {
				// console.log("allviewTableHandle233", props)
				this.propsData = {}
				this.targetForm = {}
				this.propsData = props
				this.handleType = "allviewTableHandle"
				if (props.handleType == "classifyAdd") {
					this.diaLogTitle = "分类新增"
					this.diaLogVisible = true
				} else if (props.handleType == "classifyEdit") {
					this.targetForm.iptVal = props.className
					this.diaLogTitle = "分类编辑"
					this.diaLogVisible = true
				} else if (props.handleType == "strategyEdit") {
					this.targetForm.iptVal = props.name
					this.diaLogTitle = "策略编辑"
					this.diaLogVisible = true
				} else if (props.handleType == "strategyAdd") {
					this.diaLogTitle = "策略新增"
					this.diaLogVisible = true
				}
			})
			Bus.$on("monthviewTableHandle", props => {
				console.log("monthviewTableHandle", props)
				this.propsData = {}
				this.targetForm = {}
				this.propsData = props
				this.handleType = "monthviewTableHandle"
				if (props.handleType == "classifyAdd") {
					this.diaLogTitle = "分类新增"
					this.diaLogVisible = true
				} else if (props.handleType == "classifyEdit") {
					this.targetForm.iptVal = props.className
					this.diaLogTitle = "分类编辑"
					this.diaLogVisible = true
				} else if (props.handleType == "strategyEdit") {
					this.targetForm.iptVal = props.name
					this.diaLogTitle = "策略编辑"
					this.diaLogVisible = true
				}
			})
		},
		methods: {
			closeDialog() {
				this.diaLogVisible = false
				// this.$emit('closeDialog', 'commom')
			},
			submitDialog() {
				this.$refs["targetForm"].validate(valid => {
					if (valid) {
						if (this.diaLogTitle == "分类新增") {
							let params = {
								className: this.targetForm.iptVal,
								colour: "",
								viewStrategyId: this.propsData.viewStrategyId,
								studentAccountId: this.propsData.studentId,
								year: this.propsData.year,
							}
							if (this.handleType == "monthviewTableHandle") {
								params.month = this.propsData.month
							}
							console.log("分类新增", params)
							addAllViewsClassify(params).then((res) => {
								this.$message({
									message: "分类新增成功~",
									type: 'success'
								})
								Bus.$emit("titleFormHandle", {
									handleType: "classifyAdd",
								})
								this.diaLogVisible = false
							})
						} else if (this.diaLogTitle == "分类编辑") {
							let paramsArr = ["className", "colour", "strategyType", "studentAccountId",
								"viewClassId", "year", "viewStrategyId",
							]
							let params = {}
							paramsArr.forEach(item => {
								this.$set(params, item, this.propsData[item])
							})
							params.colour = ""
							params.className = this.targetForm.iptVal
							console.log("分类编辑", this.propsData)
							editAllViewsClassify(params).then((res) => {
								this.$message({
									message: "分类编辑成功~",
									type: 'success'
								})
								Bus.$emit("titleFormHandle", {
									handleType: "classifyEdit",
									currentFirst: this.propsData.firstIndex,
									currentSecond: this.propsData.secondIndex,
									classifyName: this.targetForm.iptVal
								})
								this.diaLogVisible = false
							})
						} else if (this.diaLogTitle == "策略编辑") {
							let params = {
								colour: this.propsData.theme,
								strategyName: this.targetForm.iptVal,
								studentAccountId: this.propsData.studentId,
								viewStrategyId: this.propsData.viewStrategyId ? this.propsData.viewStrategyId :
									""
							}
							console.log("strategyEdit", params)
							editAllViewsStrategy(params).then((res) => {
								this.$message({
									message: "策略修改成功~",
									type: 'success'
								})
								Bus.$emit("titleFormHandle", {
									handleType: "strategyEdit",
									currentFirst: this.propsData.firstIndex,
									strategyName: this.targetForm.iptVal
								})
								this.diaLogVisible = false
							})
						} else if (this.diaLogTitle == "策略新增") {
							let params = {
								strategyName: this.targetForm.iptVal,
								studentAccountId: this.propsData.studentId
							}
							console.log("strategyAdd", params)
							addAllViewsStrategy(params).then((res) => {
								this.$message({
									message: "策略新增成功~",
									type: 'success'
								})
								Bus.$emit("titleFormHandle", {
									handleType: "strategyAdd"
								})
								this.diaLogVisible = false
							})
						}
					}
				})
			},
		}
	}
</script>

<style lang="scss" scoped>
	@import "../../../styles/index.scss";

	/deep/.el-date-editor.el-input :focus {
		border: 1px solid $theme_color;
	}

	/deep/.el-input__inner {}

	input::-webkit-input-placeholder {
		font-size: 14px;
		font-family: Source Han Sans CN;
		font-weight: 400;
		line-height: 20px;
		color: #999999 !important;
	}

	textarea::-webkit-input-placeholder {
		font-size: 14px;
		font-family: Source Han Sans CN;
		font-weight: 400;
		line-height: 20px;
		color: #999999 !important;
	}

	.flex_item {
		display: flex;
		align-items: center;
	}

	.flex-column {
		display: flex;
		flex-direction: column;
	}

	.flex_between {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.handle_form {
		.handle_form_row {
			margin-top: 25px;
			display: flex;
			// padding: 0 23px;

			.handle_form_item {
				// flex: 1;
				display: flex;

				.handle_form_ipt {
					width: 407px;
					border: 1px solid #EEEEEE;
					outline: none;
					font-size: 14px;
					font-family: Source Han Sans CN;
					font-weight: 400;
					line-height: 40px;
					color: #000000;
					padding: 0 10px;
					box-sizing: border-box;
				}
			}
		}

		.handle_form_btn {
			display: flex;
			align-items: center;
			margin: 40px 0 0px 0;

			.handle_form_cancel {
				margin-left: auto;
				width: 76px;
				line-height: 34px;
				background: #FFFFFF;
				border: 1px solid $theme_color;
				border-radius: 4px;
				font-size: 14px;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: $theme_color;
				text-align: center;
				cursor: pointer;
				margin-right: 16px;
			}

			.handle_form_confirm {
				width: 76px;
				line-height: 34px;
				background: $theme_color;
				border-radius: 4px;
				font-size: 14px;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #FFF;
				text-align: center;
				cursor: pointer;
			}
		}
	}
</style>
