<template>
	<el-dialog :modal="false" class="dialog_bg" :append-to-body="false" :modal-append-to-body="false" @close="closeDialog" width="579px" :title="diaLogTitle" :visible.sync="diaLogVisible">
		<div class="handle_form">
			<el-form ref="targetDetail" :rules="targetRules" :model="targetDetail" label-width="70px">
				<div class="handle_form_row flex_between">
					<div class="handle_form_item">
						<el-form-item class="flex_item" label="目标标题:">
							<span class="handle_form_ipt">{{targetDetail.title}}</span>
						</el-form-item>
					</div>
					<div class="handle_form_item">
						<el-form-item class="flex_item" label="状态:">
							<el-select :disabled="true" v-model="targetDetail.status" class="handle_form_select" clearable
								placeholder="请选择">
								<el-option v-for="item in statusList" :key="item.value" :label="item.label"
									:value="item.value">
								</el-option>
							</el-select>
						</el-form-item>
					</div>
				</div>
				<div class="handle_form_row">
					<div class="handle_form_item">
						<el-form-item class="flex_item" label="目标期限:">
							<div class="flex_item">
								<span class="handle_form_ipt">{{formatDate(targetDetail.completeDateStart)}} ~
									{{formatDate(targetDetail.completeDateEnd)}}</span>
							</div>
						</el-form-item>
					</div>
				</div>
				<div class="handle_form_row">
					<div class="handle_form_item">
						<el-form-item label-width="70px" class="flex_item" label="附件:">
							<uploadFile :showReUpload="false" @successUpload="targetUpload"
								:fileSrc="targetDetail.attPath?targetDetail.attPath:''" />
						</el-form-item>
					</div>
				</div>
			</el-form>
			<div v-if="taskList.length > 0" class="handle_form_line"></div>
			<el-form v-for="(item,index) in taskList" :key="index" :model="item" label-width="70px">
				<div class="handle_form_row flex_between">
					<div class="handle_form_item">
						<el-form-item class="flex_item" label="任务时间:">
							<span class="handle_form_ipt">{{formatDate(item.completeDateStart)}} ~
								{{formatDate(item.completeDateEnd)}}</span>
						</el-form-item>
					</div>
					<div class="handle_form_item">
						<el-form-item class="flex_item" label="状态:">
							<el-select :disabled="true" v-model="item.status" class="handle_form_select" clearable placeholder="请选择">
								<el-option v-for="item in statusList" :key="item.value" :label="item.label"
									:value="item.value">
								</el-option>
							</el-select>
						</el-form-item>
					</div>
				</div>
				<div class="handle_form_row">
					<div class="handle_form_item">
						<el-form-item class="flex_item" label="任务标题:">
							<span class="handle_form_ipt">{{item.title}}</span>
						</el-form-item>
					</div>
				</div>
				<div class="handle_form_row">
					<div class="handle_form_item">
						<el-form-item label-width="70px" class="" label="任务内容:">
							<div class="handle_form_text">
								{{item.content}}
							</div>
						</el-form-item>
					</div>
				</div>
				<div class="handle_form_row">
					<div class="handle_form_item">
						<el-form-item label-width="70px" class="flex_item" label="附件:">
							<uploadFile :showReUpload="false" @successUpload="targetUpload"
								:fileSrc="item.attPath?item.attPath:''" />
						</el-form-item>
					</div>
				</div>
				<div v-if="taskList.length > 0&&index != (taskList.length - 1)" class="handle_form_line"></div>
			</el-form>
			<!-- <div class="handle_form_row">
				<div @click="targetDetailHandle" class="handle_form_add">
					+ 继续添加任务
				</div>
			</div> -->
	<!-- 		<div class="handle_form_btn">
				<span @click="closeDialog" class="handle_form_cancel">取消</span>
				<span class="handle_form_confirm">确定</span>
			</div> -->
		</div>
	</el-dialog>
</template>

<script>
	import Bus from "./eventBus.js";
	import commonFn from "@/utils/common.js";
	import {
		detailAllViewsTarget,
		taskAllViews,
	} from "@/api/taskProgress"
	import uploadFile from "@/components/uploadFile/index.vue";
	export default {
		components: {
			uploadFile
		},
		data() {
			return {
				diaLogTitle: "目标详情查看",
				diaLogVisible: false,
				targetDetail: {},
				targetRules: {},
				statusList: [{
						label: "未完成",
						value: 1
					},
					{
						label: "已完成",
						value: 2
					}
				],
				taskList: [],
				propsData: {}
			}
		},
		mounted() {
			Bus.$on("allviewTableHandle", async props => {
				// console.log("allviewTableHandle", props)
				this.propsData = {}
				this.propsData = props
				if (props.handleType == "targetDetail") {
					await this.allViewsTargetDetail(this.propsData)
					// this.allViewsTask(this.propsData)
					this.diaLogVisible = true
				}
			})
			Bus.$on("taskFormHandle", async props => {
				// console.log("taskFormHandle", props)
				if (props.handleType == "allViewsTaskAdd") {
					await this.allViewsTargetDetail(this.propsData)
					this.allViewsTask(this.propsData)
				}
			})
		},
		methods: {
			targetUpload(props) {
				console.log("targetUpload", props)
				this.targetDetail.attPath = props.fileData
			},
			formatDate(intDate) {
				if (intDate) {
					return commonFn.timeFormat(intDate, "yyyy-MM-dd")
				} else {
					return ""
				}
			},
			// 获取总视图目标详情
			allViewsTargetDetail(intParams) {
				return new Promise((resolve, reject) => {
					let params = {
						viewTargetId: intParams.viewTargetId
					}
					detailAllViewsTarget(params).then((res) => {
						if (res.code == 0) {
							this.targetDetail = res.data
							this.$forceUpdate()
							resolve(this.targetDetail)
						} else {
							reject()
						}
					})
				})
			},
			// 获取总视图目标任务
			allViewsTask(intParams) {
				let params = {
					studentInfoId: intParams.studentId,
					viewTargetId: intParams.viewTargetId
				}
				taskAllViews(params).then((res) => {
					if (res.code == 0) {
						this.taskList = res.data
					}
				})
			},
			closeDialog() {
				this.diaLogVisible = false
				// this.$emit('closeDialog', 'commom')
			},
			targetDetailHandle() {
				// Bus.$emit("targetDetailHandle", {
				// 	handleType: "allViewsTaskAdd",
				// 	...this.targetDetail,
				// })
				this.$emit("targetDetailHandle", {
					handleType: "allViewsTaskAdd",
					...this.targetDetail,
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	@import "../../../styles/index.scss";
	
	/deep/.el-dialog__body {
		max-height: 500px;
		overflow: hidden;
		overflow-y: auto;
	}

	/deep/.el-date-editor.el-input :focus {
		border: 1px solid $theme_color;
	}

	/deep/.el-input__inner {}

	input::-webkit-input-placeholder {
		font-size: 14px;
		font-family: Source Han Sans CN;
		font-weight: 400;
		line-height: 20px;
		color: #999999 !important;
	}

	textarea::-webkit-input-placeholder {
		font-size: 14px;
		font-family: Source Han Sans CN;
		font-weight: 400;
		line-height: 20px;
		color: #999999 !important;
	}

	.flex_item {
		display: flex;
		align-items: center;
	}

	.flex-column {
		display: flex;
		flex-direction: column;
	}

	.flex_between {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.handle_form {
		.handle_form_line {
			width: 100%;
			height: 1px;
			background-color: #EEEEEE;
			margin: 14px 0;
		}
		.handle_form_row {
			margin-top: 15px;
			display: flex;
			// padding: 0 23px;

			.handle_form_add {
				width: 143px;
				line-height: 34px;
				border: 1px solid $theme_color;
				border-radius: 2px;
				text-align: center;
				font-size: 14px;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: $theme_color;
				cursor: pointer;
				margin-top: 20px;
			}

			.handle_form_item {
				flex: 1;
				display: flex;

				.handle_form_ipt {
					width: 407px;
					// border: 1px solid #EEEEEE;
					// outline: none;
					font-size: 14px;
					font-family: Source Han Sans CN;
					font-weight: 400;
					line-height: 34px;
					color: #000000;
					box-sizing: border-box;
				}

				.handle_form_text {
					font-size: 14px;
					font-family: Source Han Sans CN;
					font-weight: 400;
					line-height: 20px;
					color: #000000;
				}

				.handle_form_select {
					width: 160px;
					outline: none;
					font-size: 14px;
					font-family: Source Han Sans CN;
					font-weight: 400;
					line-height: 34px;
					color: #000000;
				}

				.handle_form_upload {
					display: flex;
					flex-direction: column;

					.form_upload_box {
						display: flex;
						align-items: center;
						justify-content: center;
						width: 128px;
						line-height: 34px;
						background: #FFFFFF;
						border: 1px solid #EEEEEE;
						border-radius: 4px;
						font-size: 14px;
						font-family: Source Han Sans CN;
						font-weight: 400;
						color: #000000;
						cursor: pointer;

						.form_upload_icon {
							width: 14px;
							height: 14px;
							margin-right: 10px;
						}
					}

					.form_upload_tip {
						font-size: 12px;
						font-family: Source Han Sans CN;
						font-weight: 400;
						line-height: 17px;
						color: #999999;
						margin-top: 5px;
					}
				}
			}
		}

		.handle_form_btn {
			display: flex;
			align-items: center;
			margin: 40px 0 0px 0;

			.handle_form_cancel {
				margin-left: auto;
				width: 76px;
				line-height: 34px;
				background: #FFFFFF;
				border: 1px solid $theme_color;
				border-radius: 4px;
				font-size: 14px;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: $theme_color;
				text-align: center;
				cursor: pointer;
				margin-right: 16px;
			}

			.handle_form_confirm {
				width: 76px;
				line-height: 34px;
				background: $theme_color;
				border-radius: 4px;
				font-size: 14px;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #FFF;
				text-align: center;
				cursor: pointer;
			}
		}
	}
</style>
