<template>
	<div class="allviewTable">
		<div class="allviewTable_content">
			<div v-if="showViews" class="table_box">
				<!-- 绘制日历 -->
				<div class="table_left">
					<div v-for="(itemOne,indexOne) in allviewData" :key="indexOne" class="table_left_inner">
						<div class="table_left_strategy">
							<!-- 日期选择 -->
							<div v-if="itemOne.tableHead" class="left_strategy_date table_head_monthviews_left">
								<div class="flex_item">
									<span>{{currentData.currentYear + '-' + currentData.currentMonth}}</span>
									<img src="@/assets/images/dashboard/moreArrow.png">
								</div>
								<el-date-picker @change="dateSelect" v-model="currentData.currentDate"
									class="strategy_date_inner" type="month" placeholder="选择年">
								</el-date-picker>
							</div>
							<!-- 策略 -->
							<div v-else @mouseenter="editCurrentTarget('strategy', itemOne)"
								@mouseleave="outCurrentTarget('strategy', itemOne)" class="left_strategy_title"
								:class="itemOne.theme? 'allviewTable_bg_'+ itemOne.theme : ''">
								<span :class="itemOne.theme? 'allviewTable_font_'+ itemOne.theme : ''"
									class="strategy_title_value">{{itemOne.name}}</span>
								<!-- <span v-if="!itemOne.isShow"
									:class="itemOne.theme? 'allviewTable_font_'+ itemOne.theme : ''"
									class="strategy_title_value">{{itemOne.name}}</span>
								<div v-if="itemOne.isShow" class="strategy_item_shadow">
									<img @click.stop="monthviewTableHandle('classifyAdd', {...itemOne, 'firstIndex': indexOne})"
										src="@/assets/images/taskProgress/add_light.png">
									<div class="item_shadow_color">
										<img class="margin_16"
											@click.stop="monthviewTableHandle('strategyColor', itemOne)"
											src="@/assets/images/taskProgress/color_light.png">
										<div v-if="itemOne.showColor" class="table_selectcolor selectcolor_bottom30">
											<div @click="monthviewTableHandle('strategyTheme', {...itemOne, ...itemColor, 'firstIndex': indexOne})"
												v-for="(itemColor,indexColor) in colorList" :key="indexColor"
												class="table_selectcolor_item"
												:class="itemOne.theme&&itemOne.theme == itemColor.theme? 'allviewTable_border_'+ itemOne.theme : ''">
												<span :style="{'background-color': itemColor.color}"></span>
											</div>
										</div>
									</div>
									<img @click.stop="monthviewTableHandle('strategyEdit', {...itemOne, 'firstIndex': indexOne})"
										src="@/assets/images/taskProgress/edit_light.png">
								</div> -->

							</div>
						</div>
						<!-- 分类-头部 -->
						<div v-if="itemOne.tableHead" class="table_left_classify">
							<div class="left_classify_list">
								<span class="left_classify_item table_head_monthviews_left">分类</span>
							</div>
						</div>
						<!-- 分类-类名 -->
						<div v-else class="table_left_classify">
							<div class="left_classify_list">
								<div @mouseenter="editCurrentTarget('classify', itemTwo)"
									@mouseleave="outCurrentTarget('classify', itemTwo)"
									v-for="(itemTwo, indexTwo) in itemOne.classify" :key="indexTwo">
									<!-- 添加分类 -->
									<!-- @click.stop="monthviewTableHandle('classifyAdd', {...itemOne, 'firstIndex': indexOne})" -->
									<span
										style="cursor: pointer;"
										:class="['table_row'+itemTwo.row,'allviewTable_'+ itemTwo.colour]"
										class="left_classify_item"
										v-if="!itemTwo.isShow&&!itemTwo.viewClassId">{{itemTwo.className}}</span>
									<!-- 已有分类 -->
									<span :class="['table_row'+itemTwo.row,'allviewTable_'+ itemTwo.colour]"
										class="left_classify_item"
										v-if="!itemTwo.isShow&&itemTwo.viewClassId">{{itemTwo.className}}</span>
									<div v-if="itemTwo.isShow&&itemTwo.viewClassId" class="classify_item_shadow"
										:class="'table_row'+itemTwo.row">
										<div class="classify_item_inner">
											<img class=""
												@click.stop="monthviewTableHandle('classTaskAdd', {...itemTwo, 'firstIndex': indexOne, 'secondIndex': indexTwo})"
												src="@/assets/images/taskProgress/add_light.png">
											<!-- <img class="margin_right_16"
												@click.stop="monthviewTableHandle('classifyColor', itemTwo)"
												src="@/assets/images/taskProgress/color_light.png">
											<img class="margin_right_16"
												@click.stop="monthviewTableHandle('classifyEdit', {...itemTwo, 'firstIndex': indexOne, 'secondIndex': indexTwo})"
												src="@/assets/images/taskProgress/edit_light.png">
											<img @click.stop="monthviewTableHandle('classifyDelete', itemTwo)"
												src="@/assets/images/taskProgress/delete_light.png">
											<div class="item_shadow_color">
												<div v-if="itemTwo.showColor"
													class="table_selectcolor selectcolor_bottom30">
													<div @click="monthviewTableHandle('classifyTheme', {...itemTwo, ...itemColor, 'firstIndex': indexOne, 'secondIndex': indexTwo})"
														v-for="(itemColor,indexColor) in colorList" :key="indexColor"
														class="table_selectcolor_item"
														:class="itemTwo.colour&&itemTwo.colour == itemColor.theme? 'allviewTable_border_'+ itemTwo.colour : ''">
														<span :style="{'background-color': itemColor.color}"></span>
													</div>
												</div>
											</div> -->
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="table_right">
					<swiper @slideChange="slideChange" class="table_right_swiper" :options="swiperOption"
						ref="mySwiper">
						<swiper-slide v-for="(itemOne,indexOne) in currentData.months" :key="indexOne">
							<!-- 头部日期 -->
							<div class="table_head_monthviews_right">
								<div :class="{'title_dark': (itemOne.current % 2) != 0, 'title_light': (itemOne.current % 2) == 0 }"
									class="right_swiper_title_monthview">
									{{itemOne.weekName}}
								</div>
								<div class="right_swiper_value_monthview">{{itemOne.value}}</div>
							</div>
							<div class="" v-for="(itemTwo,indexTwo) in allviewData" :key="indexTwo">
								<div v-if="!itemTwo.tableHead" class="">
									<div v-for="(itemThree,indexThree) in itemTwo.classify" :key="indexThree" class="">
										<div v-for="(itemFour,indexFour) in itemThree.row" :key="indexFour"
											class="right_swiper_grid">
											<div @mouseenter="editCurrentTarget('target', itemFive)"
												@mouseleave="outCurrentTarget('target', itemFive)"
												class="right_swiper_tag"
												:class="{'z_index_2': (!taskColorDetail.showColor&&!taskColorDetail.taskId) || (taskColorDetail.showColor&&taskColorDetail.taskId == itemFive.viewTaskId)}"
												v-for="(itemFive,indexFive) in itemThree.taskList" :key="indexFive">
												<div @click="monthviewTableHandle('targetDetail', itemFive)"
													class="tag_shadow_bg" :class="'tag_shadow_' + itemFive.delay"
													v-if="itemFive.isShow&&itemFive.viewClassId == itemThree.viewClassId&&itemFive.num == indexOne+1&&itemFive.row == indexFour+1">
													<img @click.stop="monthviewTableHandle('targetEdit', itemFive)"
														src="@/assets/images/taskProgress/edit_light.png">
													<div class="tag_shadow_color_month">
														<img @click.stop="monthviewTableHandle('targetColor', itemFive)"
															src="@/assets/images/taskProgress/color_light.png">
														<div v-if="itemFive.showColor"
															class="table_selectcolor selectcolor_bottom30" :style="{'right': itemFive.num >= 11 ? '0px': '-200px'}">
															<div class="table_selectcolor_btn">
																<span>颜色</span>
																<img @click="monthviewTableHandle('targetTheme', {...itemFive, 'freshTheme': 1, 'firstIndex': indexTwo, 'secondIndex': indexThree,})"
																	src="@/assets/images/taskProgress/fresh.png">
															</div>
															<div class="table_selectcolor_list">
																<div @click="monthviewTableHandle('targetTheme', {...itemFive, ...itemColor, 'firstIndex': indexTwo, 'secondIndex': indexThree,})"
																	v-for="(itemColor,indexColor) in colorList"
																	:key="indexColor" class="table_selectcolor_item"
																	:class="itemFive.colour&&itemFive.colour == itemColor.theme? 'allviewTable_border_'+ itemFive.colour : ''">
																	<span
																		:style="{'background-color': itemColor.color}"></span>
																</div>
															</div>
														</div>
													</div>
													<img @click.stop="monthviewTableHandle('taskDelete', itemFive)"
														src="@/assets/images/taskProgress/delete_light.png">
												</div>
												<div class="swiper_tag_bg"
													:class="['swiper_tag_' + itemFive.delay, 'allviewTable_'+ itemFive.colour]"
													v-if="!itemFive.isShow&&itemFive.viewClassId == itemThree.viewClassId&&itemFive.num == indexOne+1&&itemFive.row == indexFour+1">
													{{itemFive.title}}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</swiper-slide>
					</swiper>
				</div>
			</div>
			<div v-if="!showViews" class="table_empty" element-loading-spinner="el-icon-loading"
				element-loading-text="数据加载中~" v-loading.lock="!showViews">
				<!-- <img src="@/assets/images/common/empty1.png">
				<span>暂无总视图数据</span> -->
			</div>
		</div>
		<messege-box ref="messegeBox" @confirmMessege="confirmMessege" @closeMessege="closeMessege"
			:isOpen="messegeVisible" :messegeTip="messegeTip" :messegeContent="messegeContent"
			:messegeType="messegeType" />
	</div>
</template>

<script>
	import {
		swiper,
		swiperSlide
	} from "vue-awesome-swiper";
	import "swiper/dist/css/swiper.css";
	import Bus from "./eventBus.js";
	import storage from 'store';
	import {
		strategyAllViews,
		classifyAllViews,
		editAllViewsStrategy,
		editAllViewsClassify,
		deleteAllViewsClassify,
		deleteViewTask,
		editViewTask,
	} from "@/api/taskProgress";
	export default {
		components: {
			swiper,
			swiperSlide
		},
		props: {
			studentId: {
				type: String,
				default: function() {
					return ""
				}
			}
		},
		data() {
			return {
				currentData: {
					currentDate: null,
					currentYear: null,
					currentMonth: null,
					classifyName: "分类",
					months: [],
					tableHead: true
				},
				allviewData: [],
				swiperOption: {
					autoplay: false,
					freeMode: true, // 随意拖动位置
					mousewheel: false, // 鼠标滚动滚动
					slidesPerView: 14,
				},
				clickTime1: 0,
				clickTime2: 0,
				colorList: [{
						color: "#5BA897",
						theme: "theme1"
					},
					{
						color: "#D0743B",
						theme: "theme2"
					},
					{
						color: "#B39A77",
						theme: "theme3"
					},
					{
						color: "#B5B181",
						theme: "theme4"
					},
					{
						color: "#99A89C",
						theme: "theme5"
					},
					{
						color: "#D03B3B",
						theme: "theme6"
					},
					{
						color: "#4690FC",
						theme: "theme7"
					},
					{
						color: "#7285F9",
						theme: "theme8"
					},
					{
						color: "#FEBD04",
						theme: "theme9"
					},
					{
						color: "#333333",
						theme: "theme10"
					},
				],
				messegeId: "",
				messegeVisible: false,
				messegeTip: "",
				messegeContent: "",
				messegeType: "",
				taskColorDetail: {
					showColor: false,
					taskId: undefined
				},
				showViews: true
			}
		},
		created() {
			Bus.$on("titleFormHandle", props => {
				console.log("titleFormHandle", props, this.currentData)
				let nowTime = storage.get('allViewsDate')
				switch (props.handleType) {
					case "strategyEdit":
						this.allviewData[props.currentFirst].name = props.strategyName
						this.$forceUpdate()
						break;
					case "classifyEdit":
						this.allviewData[props.currentFirst].classify[props.currentSecond].className = props
							.classifyName
						break;
					case "classifyAdd":
						if (nowTime) {
							this.getCurrentDate(nowTime)
						}
						break;
				}
			})
			Bus.$on("taskFormHandle", async props => {
				console.log("taskFormHandle", props)
				let nowTime = storage.get('allViewsDate')
				if (props.handleType == "monthViewsTaskAdd" || props.handleType == "monthViewsTaskEdit") {
					if (nowTime) {
						this.getCurrentDate(nowTime)
					}
				}
			})
		},
		methods: {
			slideChange() {
				// let swiperIndex = this.$refs.mySwiper.$swiper.activeIndex
				// console.log('下标', swiperIndex)
			},
			// 获取当前年月
			async getCurrentDate(intDate) {
				this.taskColorDetail = {
					showColor: false,
					taskId: undefined
				}
				let nowTime = null
				if (intDate) {
					nowTime = new Date(intDate)
				} else {
					nowTime = new Date()
				}
				let nowYear = nowTime.getFullYear()
				let nowMonth = nowTime.getMonth() + 1
				this.currentData.currentDate = nowTime
				console.log("当前年月", nowTime)
				storage.set('monthViewsDate', nowTime, 7 * 24 * 60 * 60 * 1000)
				await this.getCuurentMonthDivide(nowYear, nowMonth)
				await this.getMonthviewDate()
				this.allviewData.unshift(this.currentData)
				this.showViews = true
			},
			// 获取指定年月天数
			getCurrentDays(intDate) {
				return new Promise((resolve, reject) => {
					let year = Number(intDate.slice(0, 4))
					let month = Number(intDate.slice(5, 7))
					let days = new Date(year, month, 0).getDate()
					console.log("当前月天数", days)
					resolve(days)
				})
			},
			// 将月数按周等分
			divideData(intData, divideNum) {
				return new Promise((resolve, reject) => {
					// console.log("divideData", intData)
					let index = 0
					let resultArr = []
					while (index < intData.length) {
						resultArr.push(intData.slice(index, index += divideNum))
					}
					resolve(resultArr)
				})
			},
			// 获取当前月的日期分隔-按周划分
			async getCuurentMonthDivide(intYear, intMonth) {
				// 获取上个月剩余天数
				let nowMonthDays = []
				let nowMonthSize = await this.getCurrentDays(`${intYear}-${intMonth}`)
				for (let a = 1; a <= nowMonthSize; a++) {
					nowMonthDays.push({
						key: "nowMonth",
						value: a,
						weekName: this.getWeekName(`${intYear}-${intMonth}-${a}`)
					})
				}
				// 获取本月第一天星期几
				let nowMonthFirst = new Date(
					`${intYear}/${intMonth}/1`
				).getDay();
				// 当前一号前剩余多少天
				let prevMonthDays = nowMonthFirst == 7 ? 0 : nowMonthFirst;
				for (let b = 1; b <= prevMonthDays; b++) {
					nowMonthDays.unshift({
						key: "prevMonth",
						value: b
					})
				}
				// 获取本月最后一天星期几
				let nowMonthLast = new Date(
					`${intYear}/${intMonth}/${nowMonthSize}`
				).getDay();
				// 获取最后一天还剩几天
				let nextMonthDays = nowMonthLast == 7 ? 6 : 6 - nowMonthLast;
				for (let c = 1; c <= nextMonthDays; c++) {
					nowMonthDays.push({
						key: "nextMonth",
						value: c
					})
				}
				// 将当月日期按周分组+过滤每周的非本月数据
				let divideArr = await this.divideData(nowMonthDays, 7)
				console.log("divideArr", divideArr)
				divideArr.map((itemOne, indexOne) => {
					itemOne.map((itemTwo, indexTwo) => {
						itemTwo.current = indexOne + 1
					})
				})
				if (divideArr && divideArr.length > 0) {
					let filterArr = divideArr.map(item => {
						return item.filter(oitem => oitem.key == "nowMonth")
					})
					// console.log("filterArr", filterArr)
					let resultArr = []
					if (filterArr && filterArr.length > 0) {
						filterArr.forEach(itemOne => {
							itemOne.forEach((itemTwo, indexTwo) => {
								resultArr.push(itemTwo)
							})
						})
					}
					console.log("resultArr2", resultArr)
					this.currentData.currentYear = intYear
					this.currentData.currentMonth = intMonth
					this.currentData.months = resultArr
				}
			},
			// 获取两个时间段的间隔周数
			getWeekInterval(startDate, endDate) {
				return new Promise((resolve, reject) => {
					let start = new Date(Date.parse(startDate.replace(/-/g, "/")))
					let end = new Date(Date.parse(endDate.replace(/-/g, "/")))
					let days = parseInt(Math.abs(end.getTime() - start.getTime()) / 1000 / 60 / 60 / 24)
					let interval = Math.ceil((days * 1 + 1) / 7)
					console.log("间隔周数", interval)
					resolve(interval)
				})

			},
			// 获取两个时间段的间隔天数
			getDayInterval(startDate, endDate) {
				return new Promise((resolve, reject) => {
					let start = new Date(Date.parse(startDate.replace(/-/g, "/")))
					let end = new Date(Date.parse(endDate.replace(/-/g, "/")))
					let interval = parseInt(Math.abs(end.getTime() - start.getTime()) / 1000 / 60 / 60 / 24)
					interval += 1
					// console.log("间隔天数", interval)
					resolve(interval)
				})
			},
			// 获取日期之间的日期
			getDateBetweenDay(startTime, endTime) {
				let dateArr = [];
				let i = 0;
				while (startTime <= endTime) {
					dateArr[i] = startTime;
					let timestamp = new Date(startTime).getTime();
					let nDate = timestamp + (24 * 60 * 60 * 1000);
					let Y = new Date(nDate).getFullYear() + '-';
					let m = (new Date(nDate).getMonth() + 1 < 10) ? '0' + (new Date(nDate).getMonth() + 1) + '-' : (
						new Date(nDate).getMonth() + 1) + '-';
					let d = (new Date(nDate).getDate() < 10) ? '0' + new Date(nDate).getDate() : new Date(nDate).getDate();
					startTime = Y + m + d;
					i++;
				}
				return dateArr;
			},
			//判断当前日期为当月第几周
			getWhichWeek(intDate) {
				let formatDate = intDate.replace(/-/g, "/")
				let newDate = new Date(formatDate)
				let year = newDate.getFullYear()
				let month = this.addZero(newDate.getMonth() + 1)
				let day = this.addZero(newDate.getDate())
				let date = new Date(year, parseInt(month) - 1, day)
				let w = date.getDay()
				let d = date.getDate()
				let week = Math.ceil((d + 6 - w) / 7)
				// console.log("当前日期第几周", week)
				return week
			},
			// 获取指定年月的星期数
			getCurrentWeeks(intYear, intMonth) {
				return new Promise((resolve, reject) => {
					var d = new Date()
					d.setFullYear(intYear, intMonth, 0)
					var monthDays = d.getDate() // 该月天数
					var weeks = 0 // 该月周数
					// 计算第一周
					d.setFullYear(intYear, intMonth - 1, 1) // 该月第一天
					var w1 = d.getDay() // 获取星期几(0~6) 该月第一天 星期几
					// 按照星期一到星期天的形式计算
					if (w1 === 0) {
						// 如果这个月的一号是这周的最后一天
						weeks = 1
						monthDays = monthDays - 1
					} else {
						// 如果这个月的一号不是这周的最后一天
						weeks = 1
						monthDays = monthDays - (7 - w1 + 1)
					}
					// 计算最后一周天数
					d.setFullYear(intYear, intMonth, 0)
					var newmonthDays = d.getDate()
					d.setFullYear(intYear, intMonth - 1, newmonthDays) // 该月第最后一天
					var w2 = d.getDay() // 获取星期几(0~6) 该月第一天 星期几
					if (w2 === 0) {
						monthDays = monthDays - 7
					} else {
						monthDays = monthDays - w2
					}
					weeks = weeks + 1 // 再加最后一周
					weeks = weeks + (monthDays / 7) // 加上剩余的周数
					console.log("当前日期有几周", weeks)
					resolve(weeks)
				})
			},
			// 获取当前日期星期几
			getWeekName(intDate) {
				let mapObj = {
					0: "日",
					1: "一",
					2: "二",
					3: "三",
					4: "四",
					5: "五",
					6: "六",
				}
				let weekName = ""
				let newDate = new Date(intDate)
				weekName = mapObj[newDate.getDay()]
				return weekName
			},
			// 截取当月任务
			getNowMonthTask(intTask) {
				return new Promise((resolve, reject) => {
					if (intTask && intTask.length > 0) {
						intTask.forEach(item => {
							this.$set(item, "completeDateStartOld", item.completeDateStart)
							this.$set(item, "completeDateEndOld", item.completeDateEnd)
							let nowYear = new Date(item.completeDateStart).getFullYear()
							let startMonth = new Date(item.completeDateStart).getMonth() + 1
							let endMonth = new Date(item.completeDateEnd).getMonth() + 1
							let endDay = new Date(nowYear, this.currentData.currentMonth, 0).getDate()
							if (startMonth != this.currentData.currentMonth) {
								item.completeDateStart = nowYear + '-' + this.addZero(this.currentData
									.currentMonth) + '-' + '01'
							}
							if (endMonth != this.currentData.currentMonth) {
								item.completeDateEnd = nowYear + '-' + this.addZero(this.currentData
									.currentMonth) + '-' + endDay
							}
						})
						resolve(intTask)
					} else {
						reject()
					}
				})
			},
			// 获取日期区间开始和结束时间所在周
			getStratEndInWeek(intArr) {
				return new Promise((resolve, reject) => {
					let originArr = JSON.parse(JSON.stringify(intArr))
					originArr.forEach(item => {
						this.$set(item, "isShow", false)
						this.$set(item, "showColor", false)
						let startWeekNum = this.getWhichWeek(item.completeDateStart)
						this.$set(item, "num", startWeekNum)
						let endWeekNum = this.getWhichWeek(item.completeDateEnd)
						let delayNum = (endWeekNum - startWeekNum) + 1
						this.$set(item, "delay", delayNum)
					})
					// console.log("getStratEndInWeek", originArr)
					resolve(originArr)
				})

			},
			extendArr(intArr) {
				let newArr = JSON.parse(JSON.stringify(intArr))
				let newList = []
				newArr.map((itemOne, indexOne) => {
					newList.push(itemOne)
					let monthDelay = this.getDateBetweenDay(itemOne.completeDateStart, itemOne
						.completeDateEnd)
					this.$set(itemOne, "monthDelay", monthDelay)
					if (monthDelay.length > 1) {
						monthDelay.forEach(itemTwo => {
							if (itemTwo != itemOne.completeDateStart) {
								newList.push({
									completeDateStart: itemTwo,
									id: "empty"
								})
							}
						})
					}
				})
				return newList
			},
			// 获取日期所在起始位置
			getStartPosition(intArr) {
				return new Promise((resolve, reject) => {
					let originArr = JSON.parse(JSON.stringify(intArr))
					originArr.forEach(async (item, index) => {
						this.$set(item, "isShow", false)
						this.$set(item, "showColor", false)
						let startNum = new Date(item.completeDateStart).getDate()
						this.$set(item, "num", startNum)
						let startWeek = this.getWhichWeek(item.completeDateStart)
						this.$set(item, "startWeek", startWeek)
						if (item.completeDateEnd) {
							let delayNum = await this.getDayInterval(item.completeDateStart, item
								.completeDateEnd)
							this.$set(item, "delay", delayNum)
						}
						this.$set(item, "row", index + 1)
					})
					// console.log("getStartPosition", originArr)
					resolve(originArr)
				})
			},
			// 获取相同区间出现最多次数
			commonDateNum(intArr) {
				var arrMap = new Map()
				let key = intArr[0].startWeek
				let value = 1
				intArr.forEach(item => {
					if (arrMap.get(item.startWeek) !== undefined) {
						let num = arrMap.get(item.startWeek)
						arrMap.set(item.startWeek, ++num)
					} else {
						arrMap.set(item.startWeek, 1)
					}
					if (arrMap.get(item.startWeek) > value) {
						key = item.startWeek
						value = arrMap.get(item.startWeek)
					}
				})
				console.log(key + '打印了' + value + '次')
				return value
			},
			// 对数组中的相同元素进行标记
			signArr(intArr) {
				let originArr = JSON.parse(JSON.stringify(intArr))
				let newArr = []
				let mapArr = []
				let resultArr = []
				originArr.map((item, index) => {
					if (mapArr.indexOf(item.startWeek) === -1) {
						newArr.push({
							commomStart: item.startWeek,
							commonArr: []
						});
						mapArr.push(item.startWeek)
					}
				});
				newArr.map(item => {
					originArr.map(items => {
						if (item.commomStart == items.startWeek) {
							item.commonArr.push(items)
						}
					})
				})
				// console.log("newArr", newArr)
				if (newArr.length > 0) {
					newArr.forEach(itemNew => {
						if (itemNew.commonArr && itemNew.commonArr.length > 0) {
							itemNew.commonArr.forEach((itemCommon, indexCommon) => {
								let newObj = {
									...itemCommon,
									row: indexCommon + 1
								}
								resultArr.push(newObj)
							})
						}
					})
				}
				// console.log("signArr", resultArr)
				return resultArr
			},
			addZero(num) {
				if (num < 10)
					return "0" + num;
				return num;
			},
			// 数组根据ID排序
			sortArrWith(property) {
				return function(a, b) {
					var sort1 = a[property] * 1;
					var sort2 = b[property] * 1;
					return sort1 - sort2
				}
			},
			// 获取月视图策略
			monthViewsStrategy() {
				return new Promise((resolve, reject) => {
					let params = {
						studentInfoId: this.studentId,
					}
					strategyAllViews(params).then((res) => {
						if (res.code == 0) {
							let strategys = []
							if (res.data && res.data.length > 0) {
								strategys = res.data.sort(this.sortArrWith("viewStrategyId"))
								resolve(strategys)
							} else {
								resolve(strategys)
							}
						} else {
							this.showViews = true
							reject()
						}
					})
				})
			},
			// 获取月视图分类数据
			async monthViewsClassify() {
				return new Promise((resolve, reject) => {
					let params = {
						studentInfoId: this.studentId,
						year: this.currentData.currentYear,
						month: this.currentData.currentMonth,
					}
					classifyAllViews(params).then(res => {
						if (res.code == 0) {
							// console.log("classifyEmpty", classifyEmpty)
							if (res.data && res.data.length > 0) {
								let resData = res.data
								// console.log("获取月视图分类数据-分类数据1", resData)
								resData.forEach(async item => {
									this.$set(item, "isShow", false)
									this.$set(item, "showColor", false)
									if (item.targetList && item.targetList.length > 0) {
										this.$set(item, "colour", item.targetList[0]
											.colour)
									} else {
										this.$set(item, "colour", "")
									}
									if (item.taskList && item.taskList.length > 0) {
										item.taskList = await this.getNowMonthTask(item
											.taskList)
										item.taskList = await this.getStartPosition(item
											.taskList)
										// console.log("获取月视图分类数据-分类数据111", item.taskList)
										// let commonNum = this.commonDateNum(item.taskList)
										let commonNum = item.taskList.length
										this.$set(item, "row", commonNum)
										// item.taskList = this.signArr(item.taskList)
									} else {
										this.$set(item, "row", 1)
									}
								})
								resolve(resData)
							} else {
								this.showViews = true
								resolve([])
							}
						} else {
							this.showViews = true
							reject()
						}
					})
				})
			},
			// 将分类按照相同策略id分组
			async groupIntoClassify(intData) {
				return new Promise((resolve, reject) => {
					let hasStrategyId = intData.filter(item => {
						return item.viewStrategyId
					})
					// console.log("groupIntoClassify1", hasStrategyId)
					if (hasStrategyId && hasStrategyId.length > 0) {
						let keys = hasStrategyId.map((item) => {
							if (item.viewStrategyId) {
								return item.viewStrategyId;
							}
						});
						keys = [...new Set(keys)].sort();
						console.log("groupIntoClassify2", keys)
						let result = {};
						keys.forEach((item) => {
							result[item] = [];
						});
						hasStrategyId.map((item) => {
							keys.some((value) => {
								if (item.viewStrategyId == value) {
									result[value].push(item);
									return;
								}
							});
						});
						// keys.forEach(item => {
						// 	// 分类ID排序
						// 	result[item] = result[item].sort(this.sortArrWith("viewClassId"))
						// 	if(result[item].targetList&&result[item].targetList.length > 0) {
						// 		// 任务ID排序
						// 		result[item].targetList = result[item].targetList.sort(this.sortArrWith("viewTargetId"))
						// 	}
						// })
						console.log("groupIntoClassify3", result)
						this.showViews = true
						resolve(result)
					} else {
						this.showViews = true
						resolve([])
					}
				})
			},
			// 获取月视图数据
			async getMonthviewDate() {
				this.showViews = false
				let strategy = await this.monthViewsStrategy()
				// console.log("获取月视图数据-策略数据", strategy)
				let classifys = await this.monthViewsClassify()
				// console.log("获取月视图数据-分类数据2", classifys)
				let groupIntoClass = await this.groupIntoClassify(classifys)
				// console.log("获取总视图数据-分类数据分类", groupIntoClass)
				return new Promise((resolve, reject) => {
					this.allviewData = []
					if (strategy && strategy.length > 0) {
						strategy.forEach(item => {
							if (groupIntoClass[item.viewStrategyId]) {
								this.allviewData.push({
									viewStrategyId: item.viewStrategyId ? item.viewStrategyId :
										"",
									theme: item.colour ? item.colour : "",
									name: item.strategyName,
									showColor: false,
									isShow: false,
									classify: groupIntoClass[item.viewStrategyId]
								})
							} else {
								let classifyEmpty = {
									className: "暂无分类",
									row: 1,
									viewStrategyId: item.viewStrategyId,
									studentId: this.studentId,
									year: this.currentData.currentYear
								}
								this.allviewData.push({
									viewStrategyId: item.viewStrategyId ? item.viewStrategyId :
										"",
									theme: item.colour ? item.colour : "",
									name: item.strategyName,
									showColor: false,
									isShow: false,
									classify: [classifyEmpty]
								})
							}
						})
					}
					this.showViews = true
					console.log("获取月视图数据-最终数据", this.allviewData)
					resolve(this.allviewData)
				})
			},
			dateSelect(value) {
				console.log("dateSelect", value)
				storage.set('allViewsDate', value, 7 * 24 * 60 * 60 * 1000)
				this.getCurrentDate(value)
			},
			editCurrentTarget(type, item) {
				// console.log("editCurrentTarget", type, item)
				switch (type) {
					case "classify":
						item.isShow = true
						break;
					case "target":
						item.isShow = true
						break;
					case "strategy":
						item.isShow = true
						break;

				}
			},
			outCurrentTarget(type, item) {
				switch (type) {
					case "classify":
						item.isShow = false
						item.showColor = false
						break;
					case "target":
						this.taskColorDetail = {
							showColor: false,
							taskId: undefined
						}
						item.isShow = false
						item.showColor = false
						this.$forceUpdate()
						break;
					case "strategy":
						item.isShow = false
						item.showColor = false
						break;
				}
			},
			closeMessege() {
				this.messegeVisible = false;
				this.messegeId = "";
			},
			confirmMessege() {
				switch (this.messegeTip) {
					case "删除任务":
						deleteViewTask({
							viewTaskId: this.messegeId
						}).then((res) => {
							if (res.code == 0) {
								this.$message({
									message: "任务删除成功~",
									type: 'success'
								})
								this.getCurrentDate()
								this.messegeVisible = false
							}
						})

						break;
					case "删除分类":
						deleteAllViewsClassify({
							viewClassId: this.messegeId
						}).then((res) => {
							if (res.code == 0) {
								this.$message({
									message: "分类删除成功~",
									type: 'success'
								})
								this.getCurrentDate()
								this.messegeVisible = false
							}
						})

						break;
				}
			},
			monthviewTableHandle(type, item) {
				if (type == "targetDetail") {
					try {
						if (this.clickTime1 == 0) {
							this.clickTime1 = new Date().getTime()
							setTimeout(() => {
								if (this.clickTime2 == 0) {
									console.log('单击事件')
									this.clickTime1 = 0
								}
							}, 310)
						} else {
							this.clickTime2 = new Date().getTime()
							if (this.clickTime2 - this.clickTime1 <= 300) {
								console.log('这是双击')
								// this.$emit("monthviewTaskHandle", {
								// 	year: this.currentData.currentYear,
								// 	studentId: this.studentId,
								// 	handleType: type,
								// 	...item
								// })
							}
							this.clickTime1 = 0
							setTimeout(() => {
								this.clickTime2 = 0
							}, 320)
						}
					} catch (e) {
						console.log(e)
					}
				} else if (type == "strategyColor") {
					item.showColor = !item.showColor
				} else if (type == "strategyTheme") {
					let params = {
						colour: item.theme,
						strategyName: item.name,
						strategyType: item.type,
						studentAccountId: this.studentId,
						viewStrategyId: item.viewStrategyId ? item.viewStrategyId : "",
					}
					console.log("strategyTheme", item, this.allviewData[item.firstIndex])
					editAllViewsStrategy(params).then((res) => {
						this.$message({
							message: "策略颜色修改成功~",
							type: 'success'
						})
						this.allviewData[item.firstIndex].theme = item.theme
						this.allviewData[item.firstIndex].showColor = false
						this.$forceUpdate()
					})
				} else if (type == "classifyColor") {
					item.showColor = !item.showColor
				} else if (type == 'classifyTheme') {
					console.log("classifyTheme", item)
					let paramsArr = ["className", "strategyType", "studentAccountId", "viewClassId", "year", ]
					let params = {}
					paramsArr.forEach(keyItem => {
						this.$set(params, keyItem, item[keyItem])
					})
					params.colour = item.theme
					editAllViewsClassify(params).then((res) => {
						this.$message({
							message: "分类颜色修改成功~",
							type: 'success'
						})
						this.allviewData[item.firstIndex].classify[item.secondIndex].colour = item.theme
						this.allviewData[item.firstIndex].classify[item.secondIndex].isShow = false
						this.allviewData[item.firstIndex].classify[item.secondIndex].showColor = false
						this.$forceUpdate()
					})
				} else if (type == "targetColor") {
					console.log("targetColor", item)
					item.showColor = !item.showColor
					this.taskColorDetail = {
						showColor: true,
						taskId: item.viewTaskId
					}
					if (!item.showColor) {
						this.taskColorDetail = {
							showColor: false,
							taskId: undefined
						}
					}
				} else if (type == 'targetTheme') {
					console.log("targetTheme", item)
					let params = {
						attPath: item.attPath,
						completeDateStart: item.completeDateStartOld,
						completeDateEnd: item.completeDateEndOld,
						studentAccountId: item.studentAccountId,
						viewClassId: item.viewClassId,
						title: item.title,
						viewTaskId: item.viewTaskId,
						status: item.status,
						sendStatus: item.sendStatus,
						content: item.content,
					}
					if (item.freshTheme) {
						params.colour = ""
					} else {
						params.colour = item.theme
					}
					let targetArr = this.allviewData[item.firstIndex].classify[item.secondIndex].taskList
					let thirdIndex = targetArr.findIndex(itemTarget => itemTarget.viewTaskId == item.viewTaskId)
					console.log("targetTheme", thirdIndex)
					// console.log("任务颜色修改", params)
					editViewTask(params).then((res) => {
						if (res.code == 0) {
							if (item.freshTheme) {
								this.$message({
									message: "任务颜色重置成功~",
									type: 'success'
								})
								this.allviewData[item.firstIndex].classify[item.secondIndex].taskList[thirdIndex]
									.colour = ""
							} else {
								this.$message({
									message: "任务颜色修改成功~",
									type: 'success'
								})
								this.allviewData[item.firstIndex].classify[item.secondIndex].taskList[thirdIndex]
									.colour = item.theme
							}
							this.allviewData[item.firstIndex].classify[item.secondIndex].taskList[thirdIndex]
								.showColor = false
							this.allviewData[item.firstIndex].classify[item.secondIndex].taskList[thirdIndex]
								.isShow = false
						}
					})
				} else if (type == "classifyDelete") {
					this.messegeId = item.viewClassId;
					this.messegeTip = "删除分类";
					this.messegeContent = "确定删除此分类吗?";
					this.messegeVisible = true;
					this.messegeType = "warn";
				} else if (type == "taskDelete") {
					this.messegeId = item.viewTaskId;
					this.messegeTip = "删除任务";
					this.messegeContent = "确定删除此任务吗?";
					this.messegeVisible = true;
					this.messegeType = "warn";
				} else if (type == "classTaskAdd" || type == "targetEdit") {
					this.$emit("monthviewTaskHandle", {
						year: this.currentData.currentYear,
						studentId: this.studentId,
						handleType: type,
						...item
					})
				} else {
					// console.log("monthviewTableHandle>>>>>")
					Bus.$emit("monthviewTableHandle", {
						year: this.currentData.currentYear,
						studentId: this.studentId,
						handleType: type,
						...item
					})
				}
			}
		},
	}
</script>

<style lang="scss" scoped="">
	@import "../../../styles/index.scss";
	@import "./common.scss";

	/deep/.swiper-slide {
		width: 85px !important;
	}

	/deep/.swiper-container {
		// width: 1185px !important;
		width: 1200px !important;
		margin: 0;
		padding: 0;
	}

	/deep/.left_strategy_date .el-input__inner {
		cursor: pointer;
	}

	.relative {
		position: relative;
	}

	.flex_item {
		display: flex;
		align-items: center;
	}

	// .swiper_tag_1,
	// .tag_shadow_1 {
	// 	width: 237px;
	// }

	.swiper_tag_1,
	.tag_shadow_1 {
		// width: 170px; // 一页7个
		// width: 120px; // 一页10个
		// width: 80px; // 一页15个
		width: 84px; // 一页14个 // 全部都减了1，露出格子
	}

	.swiper_tag_2,
	.tag_shadow_2 {
		// width: 340px;
		// width: 240px;
		// width: 160px;
		width: 169px;
	}

	.swiper_tag_3,
	.tag_shadow_3 {
		// width: 510px;
		// width: 360px;
		// width: 240px;
		width: 254px;
	}

	.swiper_tag_4,
	.tag_shadow_4 {
		// width: 680px;
		// width: 480px;
		// width: 320px;
		width: 339px;
	}

	.swiper_tag_5,
	.tag_shadow_5 {
		// width: 850px;
		// width: 600px;
		// width: 400px;
		width: 424px;
	}

	.swiper_tag_6,
	.tag_shadow_6 {
		// width: 1020px;
		// width: 720px;
		// width: 480px;
		width: 509px;
	}

	.swiper_tag_7,
	.tag_shadow_7 {
		// width: 1190px;
		// width: 840px;
		// width: 560px;
		width: 594px;
	}

	.swiper_tag_8,
	.tag_shadow_8 {
		// width: 1360px;
		// width: 960px;
		// width: 640px;
		width: 679px;
	}

	.swiper_tag_9,
	.tag_shadow_9 {
		// width: 1530px;
		// width: 1080px;
		// width: 720px;
		width: 764px;
	}

	.swiper_tag_10,
	.tag_shadow_10 {
		// width: 1700px;
		// width: 1200px;
		// width: 800px;
		width: 849px;
	}

	.swiper_tag_11,
	.tag_shadow_11 {
		// width: 1870px;
		// width: 1320px;
		// width: 880px;
		width: 934px;
	}

	.swiper_tag_12,
	.tag_shadow_12 {
		// width: 2040px;
		// width: 1440px;
		// width: 960px;
		width: 1019px;
	}

	.swiper_tag_13,
	.tag_shadow_13 {
		// width: 2210px;
		// width: 1560px;
		// width: 1040px;
		width: 1104px;
	}

	.swiper_tag_14,
	.tag_shadow_14 {
		// width: 2380px;
		// width: 1680px;
		// width: 1120px;
		width: 1189px;
	}

	.swiper_tag_15,
	.tag_shadow_15 {
		// width: 2550px;
		// width: 1800px;
		// width: 1200px;
		width: 1274px;
	}

	.swiper_tag_16,
	.tag_shadow_16 {
		// width: 2720px;
		// width: 1920px;
		// width: 1280px;
		width: 1359px;
	}

	.swiper_tag_17,
	.tag_shadow_17 {
		// width: 2890px;
		// width: 2040px;
		// width: 1360px;
		width: 1444px;
	}

	.swiper_tag_18,
	.tag_shadow_18 {
		// width: 3060px;
		// width: 2160px;
		// width: 1440px;
		width: 1529px;
	}

	.swiper_tag_19,
	.tag_shadow_19 {
		// width: 3230px;
		// width: 2280px;
		// width: 1520px;
		width: 1614px;
	}

	.swiper_tag_20,
	.tag_shadow_20 {
		// width: 3400px;
		// width: 2400px;
		// width: 1600px;
		width: 1699px;
	}

	.swiper_tag_21,
	.tag_shadow_21 {
		// width: 3570px;
		// width: 2520px;
		// width: 1680px;
		width: 1784px;
	}

	.swiper_tag_22,
	.tag_shadow_22 {
		// width: 3740px;
		// width: 2640px;
		// width: 1760px;
		width: 1869px;
	}

	.swiper_tag_23,
	.tag_shadow_23 {
		// width: 3910px;
		// width: 2760px;
		// width: 1840px;
		width: 1954px;
	}

	.swiper_tag_24,
	.tag_shadow_24 {
		// width: 4080px;
		// width: 2880px;
		// width: 1920px;
		width: 2039px;
	}

	.swiper_tag_25,
	.tag_shadow_25 {
		// width: 4250px;
		// width: 3000px;
		// width: 2000px;
		width: 2124px;
	}

	.swiper_tag_26,
	.tag_shadow_26 {
		// width: 4420px;
		// width: 3120px;
		// width: 2080px;
		width: 2209px;
	}

	.swiper_tag_27,
	.tag_shadow_27 {
		// width: 4590px;
		// width: 3240px;
		// width: 2160px;
		width: 2294px;
	}

	.swiper_tag_28,
	.tag_shadow_28 {
		// width: 4760px;
		// width: 3360px;
		// width: 2240px;
		width: 2379px;
	}

	.swiper_tag_29,
	.tag_shadow_29 {
		// width: 4930px;
		// width: 3480px;
		// width: 2320px;
		width: 2464px;
	}

	.swiper_tag_30,
	.tag_shadow_30 {
		// width: 5100px;
		// width: 3600px;
		// width: 2400px;
		width: 2549px;
	}

	.swiper_tag_31,
	.tag_shadow_31 {
		// width: 5270px;
		// width: 3720px;
		// width: 2480px;
		width: 2634px;
	}
</style>
