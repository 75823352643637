<template>
	<el-dialog :modal="false" class="dialog_bg" :append-to-body="false" :modal-append-to-body="false" @close="closeDialog" width="579px" :title="diaLogTitle" :visible.sync="diaLogVisible">
		<div class="" v-if="diaLogTitle != '草稿箱'">
			<div class="summary_tab">
				<span @click="changeTab(0)" :class="{'tab_item_active': currentTab == 0}"
					class="summary_tab_item">添加纪要</span>
				<span @click="changeTab(1)" :class="{'tab_item_active': currentTab == 1}"
					class="summary_tab_item">历史纪要</span>
			</div>
			<div v-if="currentTab == 0" class="handle_form">
				<el-form ref="targetForm" :rules="targetRules" :model="targetForm" label-width="70px">
					<div class="handle_form_row">
						<div class="handle_form_item">
							<el-form-item prop="content" class="flex_item">
								<textarea v-model="targetForm.content" class="handle_form_textarea" placeholder="请输入" />
							</el-form-item>
						</div>
					</div>
				</el-form>
				<div class="handle_form_btn">
					<div @click="saveDraft" class="form_btn_right">
						<img src="@/assets/images/taskProgress/draft.png">
						<span>草稿箱</span>
					</div>
					<div class="form_btn_left">
						<span v-preventReClick @click="submitSumery(1)" class="handle_form_cancel">保存至草稿箱</span>
						<span v-preventReClick @click="submitSumery(2)" class="handle_form_confirm">确定并发送到小程序</span>
					</div>
				</div>
			</div>
			<div v-if="currentTab == 1" class="summary_history_list">
				<div v-for="(item,index) in summaryViewsList" :key="index" class="summary_history_item">
					<div class="history_item_time">
						记录时间: {{formatDate(item.createTime)}}
					</div>
					<div class="history_item_content">
						<div :class="{'overflow-hidden':!isShowMore}">
							记录内容: {{item.content}}
							<span v-if="isShowMore" class="link" @click="handleShowMoreClick"
							>收起</span>
							<span
							v-else
							class="show-more-btn"
							@click="handleShowMoreClick"
							>
							<span>...</span>
							<span class="link">展开</span></span>
						</div>
					</div>
					<div v-if="index != summaryViewsList.length - 1" class="summary_line"></div>
				</div>
				<div v-if="summaryViewsList.length == 0" class="summary_history_empty">
					<img src="@/assets/images/common/empty1.png">
					<span>暂无纪要</span>
				</div>
			</div>
		</div>
		<div class="" v-if="diaLogTitle == '草稿箱'">
			<div class="summary_draft_list">
				<div v-for="(item,index) in summaryViewsDraft" :key="index" class="summary_draft_item">
					<div class="draft_item_top">
						<span class="item_top_time">记录时间：{{item.summaryDate}}</span>
						<span @click="draftEdit(item)" class="item_top_btn">继续编辑></span>
					</div>
					<div class="draft_item_bottom">
						记录内容: {{item.content}}
					</div>
					<div v-if="index != summaryViewsDraft.length - 1" class="summary_line"></div>
				</div>
				<div v-if="summaryViewsDraft.length == 0" class="summary_history_empty">
					<img src="@/assets/images/common/empty1.png">
					<span>暂无草稿</span>
				</div>
			</div>
		</div>
	</el-dialog>
</template>

<script>
	import Bus from "./eventBus.js";
	import {
		summaryViewsTarget,
		addViewsTargetSummary,
		editViewsTargetSummary
	} from "@/api/taskProgress"
	export default {
		data() {
			return {
				diaLogTitle: "",
				diaLogVisible: false,
				targetForm: {},
				targetRules: {
					content: [{
						required: true,
						message: '请输入纪要内容',
						trigger: 'change'
					}]
				},
				currentTab: 0,
				summaryViewsList: [],
				summaryViewsDraft: [],
				isShowMore:false
			}
		},
		props: {
			studentId: {
				type: String,
				default: function() {
					return ""
				}
			}
		},
		mounted() {},
		methods: {
			formatDate(intDate) {
				let date = new Date(intDate)
				let year = date.getFullYear()
				let month = date.getMonth() + 1
				let day = date.getDate()
				let hour = date.getHours()
				let min = date.getMinutes()
				return year + '-' + this.addZero(month) + '-' + this.addZero(day) + ' ' + this.addZero(hour) + ':' + this.addZero(min)
			},
			addZero(num) {
				if (num < 10)
					return "0" + num;
				return num;
			},
			closeDialog() {
				this.diaLogVisible = false
				// this.$emit('closeDialog', 'commom')
			},
			// 获取既要记录
			viewsTargetSummary(num) {
				let params = {
					draft: num,
					studentInfoId: this.studentId,
				}
				summaryViewsTarget(params).then((res) => {
					if (res.code == 0) {
						if (num == 1) {
							this.summaryViewsDraft = res.data
						} else {
							this.summaryViewsList = res.data
						}
						
					}
				})
			},
			handleShowMoreClick() {
				this.isShowMore = !this.isShowMore;
			},
			// 添加纪要
			viewsTargetSummaryAdd(num) {
				let params = {
					content: this.targetForm.content,
					draft: num,
					studentAccountId: this.studentId,
					sendStatus: num
				}
				if(this.targetForm.taskSummaryId) {
					this.$set(params, "taskSummaryId", this.targetForm.taskSummaryId)
					editViewsTargetSummary(params).then((res) => {
						if (res.code == 0) {
							if (num == 1) {
								this.$message({
									message: "保存草稿成功~",
									type: 'success'
								})
								this.saveDraft()
							} else {
								this.$message({
									message: "发送成功~",
									type: 'success'
								})
								this.diaLogVisible = false
							}
						}
					})
				} else {
					addViewsTargetSummary(params).then((res) => {
						if (res.code == 0) {
							if (num == 1) {
								this.$message({
									message: "保存草稿成功~",
									type: 'success'
								})
								this.saveDraft()
							} else {
								this.$message({
									message: "发送成功~",
									type: 'success'
								})
								this.diaLogVisible = false
							}
						}
					})
				}
			},
			submitSumery(num) {
				this.$refs["targetForm"].validate(valid => {
					if (valid) {
						this.viewsTargetSummaryAdd(num)
					}
				})
			},
			getSummaryData() {
				this.currentTab = 0
				this.targetForm = {}
				this.$nextTick(() => {
					this.$refs.targetForm.clearValidate()
				})
				this.diaLogTitle = "纪要记录"
				this.diaLogVisible = true
			},
			changeTab(num) {
				this.currentTab = num
				if (num == 1) {
					this.viewsTargetSummary(2)
				} else {
					this.targetForm = {}
				}
			},
			saveDraft() {
				this.diaLogTitle = "草稿箱"
				this.viewsTargetSummary(1)
			},
			draftEdit(item) {
				this.targetForm = {}
				this.targetForm = item
				this.diaLogTitle = "纪要记录"
				this.$forceUpdate()
			},
		}
	}
</script>

<style lang="scss" scoped>
	@import "../../../styles/index.scss";

	/deep/.el-date-editor.el-input :focus {
		border: 1px solid $theme_color;
	}

	/deep/.el-input__inner {}

	/deep/.el-dialog__body {
		height: 400px;
		overflow: hidden;
		overflow-y: auto;
	}

	input::-webkit-input-placeholder {
		font-size: 14px;
		font-family: Source Han Sans CN;
		font-weight: 400;
		line-height: 20px;
		color: #999999 !important;
	}

	textarea::-webkit-input-placeholder {
		font-size: 14px;
		font-family: Source Han Sans CN;
		font-weight: 400;
		line-height: 20px;
		color: #999999 !important;
	}

	.flex_item {
		display: flex;
		align-items: center;
	}

	.flex-column {
		display: flex;
		flex-direction: column;
	}

	.flex_between {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.summary_line {
		width: 100%;
		height: 1px;
		background-color: #EEEEEE;
		margin: 24px 0;
	}

	.summary_tab {
		display: flex;
		align-items: center;
		margin: 24px 24px;

		.summary_tab_item {
			font-size: 14px;
			font-family: Source Han Sans CN;
			font-weight: 400;
			line-height: 34px;
			color: #333333;
			padding: 0 20px;
			border: 1px solid #EEEEEE;
			border-radius: 4px;
			cursor: pointer;
			margin-right: 12px;

			&.tab_item_active {
				border: 1px solid $theme_color;
				color: $theme_color;
			}
		}
	}

	.handle_form {
		.handle_form_row {
			margin-top: 25px;
			display: flex;
			// padding: 0 23px;

			.handle_form_item {
				// flex: 1;
				display: flex;

				.handle_form_textarea {
					width: 483px;
					height: 200px;
					border: 1px solid #EEEEEE;
					outline: none;
					font-size: 14px;
					font-family: Source Han Sans CN;
					font-weight: 400;
					color: #000000;
					padding: 10px 10px;
					box-sizing: border-box;
					resize: none;
					margin: 0 0 0 24px;
				}
			}
		}

		.handle_form_btn {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin: 40px 0 0px 0;

			.form_btn_right {
				display: flex;
				align-items: center;
				cursor: pointer;

				img {
					width: 18px;
					height: 18px;
					margin-right: 10px;
				}

				span {
					font-size: 14px;
					font-family: Source Han Sans CN;
					font-weight: 400;
					line-height: 20px;
					color: #666666;
				}
			}

			.form_btn_left {
				display: flex;

				.handle_form_cancel {
					margin-left: auto;
					width: 132px;
					line-height: 34px;
					background: #FFFFFF;
					border: 1px solid $theme_color;
					border-radius: 4px;
					font-size: 14px;
					font-family: Source Han Sans CN;
					font-weight: 400;
					color: $theme_color;
					text-align: center;
					cursor: pointer;
					margin-right: 16px;
				}

				.handle_form_confirm {
					width: 174px;
					line-height: 34px;
					background: $theme_color;
					border-radius: 4px;
					font-size: 14px;
					font-family: Source Han Sans CN;
					font-weight: 400;
					color: #FFF;
					text-align: center;
					cursor: pointer;
				}
			}
		}
	}
	
	.summary_history_empty {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	
		img {
			width: 250px;
			height: 250px;
		}
	}

	.summary_history_list {
		display: flex;
		flex-direction: column;
		margin: 0 24px;

		.summary_history_item {
			display: flex;
			flex-direction: column;

			.history_item_time {
				font-size: 14px;
				font-family: Source Han Sans CN;
				font-weight: 400;
				line-height: 20px;
				color: #999999;
				margin-bottom: 8px;
			}

			.history_item_content {
				font-size: 14px;
				font-family: Source Han Sans CN;
				font-weight: 400;
				line-height: 24px;
				// max-height: 48px;
				color: #333333;
				// margin-bottom: 20px;
				// overflow: hidden;
				// text-overflow: ellipsis;
				// display: -webkit-box;
				// -webkit-box-orient: vertical;
				// -webkit-line-clamp: 2;
				// word-break: break-all;
			}
			.show-more-btn {
			position: absolute;
			display: block;
			right: 0;
			bottom: 0;
			width: 70px;
			text-align: right;
			/* 背景从透明到白色，过渡 */
			background-image: linear-gradient(to right, transparent, #ffffff 26.4%);
			}
			.overflow-hidden {
				position: relative;
				overflow: hidden;
				max-height:48px;
			}

			.link {
			color: #409eff;
			cursor: pointer;
			}
		}
	}

	.summary_draft_list {
		display: flex;
		flex-direction: column;
		margin: 24px 20px 0 20px;

		.summary_draft_item {
			display: flex;
			flex-direction: column;

			.draft_item_top {
				display: flex;
				align-items: center;
				justify-content: space-between;
				margin-bottom: 10px;

				.item_top_time {
					font-size: 14px;
					font-family: Source Han Sans CN;
					font-weight: 400;
					line-height: 20px;
					color: #999999;
				}

				.item_top_btn {
					font-size: 14px;
					font-family: Source Han Sans CN;
					font-weight: 400;
					line-height: 20px;
					color: $theme_color;
					padding: 0 8px;
					border: 1px solid $theme_color;
					border-radius: 4px;
					cursor: pointer;
				}
			}

			.draft_item_bottom {
				font-size: 14px;
				font-family: Source Han Sans CN;
				font-weight: 400;
				line-height: 24px;
				color: #333333;
				// margin-bottom: 20px;
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 2;
				word-break: break-all;
			}
		}
	}
</style>
