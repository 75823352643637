<!--
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2021-06-04 18:10:10
 * @LastEditTime: 2022-02-25 11:47:32
-->
<template>
	<div class="taskProgress">
		<div class="taskProgress_tab">
			<module-list :moduleList="moduleList" :currentTab="currentTab" @changeTab="changeTab" />
		</div>
		<div class="taskProgress_content">
			<div class="taskProgress_left">
				<!-- 学生列表 -->
				<student-list :studentIndex="studentIndex" :intList="studentList" @selectStudent="selectStudent"
					@searchStudent="searchStudent" />
			</div>
			<div class="taskProgress_right">
				<!-- 总视图 -->
				<div v-if="currentTab == '总视图'" class="taskProgress_right_allview">
					<allview-table ref="allViews" :studentId="studentId" @allviewTaskHandle="allviewTaskHandle" />
				</div>
				<!-- 月视图 -->
				<div v-if="currentTab == '月视图'" class="taskProgress_right_monthview">
					<monthview-table ref="monthViews" :studentId="studentId"
						@monthviewTaskHandle="monthviewTaskHandle" />
				</div>
				<!-- 任务发放记录 -->
				<div v-if="currentTab == '任务发放记录'" class="taskProgress_right_history">
					<history-table ref="grantHistory" :studentId="studentId" />
				</div>
			</div>
		</div>
		<!-- 操作弹窗 -->
		<div class="">
			<!-- 目标标题编辑-专注考试 -->
			<target-form />
			<!-- 目标详情-专注考试 -->
			<target-detail @targetDetailHandle="targetDetailHandle" />
			<!-- 策略编辑-高中学校策略 -->
			<title-form />
			<!-- 纪要记录 -->
			<summary-form ref="summaryForm" :studentId="studentId" />
			<!-- 发放任务 -->
			<task-form ref="taskForm" :studentId="studentId" />
		</div>
		<div @mouseenter="enterBtn" @mouseleave="outBtn"
			v-if="currentTab != '任务发放记录'" class="taskProgress_btn" :class="{'taskProgress_btn_right': showBtn}">
			<img class="small_img" :class="{'big_img': showBtn}" v-if="currentTab == '月视图'" @click="handleFn('task')"
				src="@/assets/images/taskProgress/submit.png">
			<img class="small_img" :class="{'big_img': showBtn}" @click="handleFn('summary')"
				src="@/assets/images/taskProgress/text.png">
		</div>
	</div>
</template>
<script>
	import moduleList from "@/components/moduleList/index.vue";
	import studentList from "@/components/studentList/index.vue";
	import allviewTable from "./components/allviewTable.vue";
	import monthviewTable from "./components/monthviewTable.vue";
	import historyTable from "./components/historyTable.vue";
	import targetForm from "./components/targetForm.vue";
	import targetDetail from "./components/targetDetail.vue";
	import titleForm from "./components/titleForm.vue";
	import summaryForm from "./components/summaryForm.vue";
	import taskForm from "./components/taskForm.vue";
	import commonFn from "@/utils/common.js";
	import {
		listStudents,
	} from "@/api/common";
	import storage from 'store';
	import {
		mapActions
	} from 'vuex'
	export default {
		components: {
			moduleList,
			studentList,
			allviewTable,
			monthviewTable,
			historyTable,
			targetForm,
			targetDetail,
			titleForm,
			summaryForm,
			taskForm,
		},
		data() {
			return {
				currentTab: '',
				moduleList: [
					// {
					// 	label: "总视图",
					// 	value: '1'
					// },
					// {
					// 	label: "月视图",
					// 	value: '2'
					// },
					// {
					// 	label: "任务发放记录",
					// 	value: '3'
					// },
				],
				studentList: [],
				studentId: "",
				studentName: "",
				studentIndex: 0,
				showBtn: false
			}
		},
		destroyed() {
			// console.log("beforeDestroy")
			storage.remove('currentTab')
		},
		async created() {
			console.log("任务进度路由>>>>", this.$route)
			this.moduleList = []
			if (this.$route.meta.secondaryMenu) {
				let secondaryMenu = this.$route.meta.secondaryMenu
				this.moduleList = commonFn.secondaryMenu(secondaryMenu)
				if (this.$route.query.type) {
					let tabMap = {
						1: "总视图",
						2: "月视图",
					}
					this.currentTab = tabMap[this.$route.query.type]
				} else {
					if (storage.get('currentTab')) {
						this.currentTab = storage.get('currentTab')
					} else {
						this.currentTab = this.moduleList[0].label
					}
				}
			}
			await this.studentLists()
			this.differentTabFn()
		},
		methods: {
			...mapActions(['studentUpdateClear']),
			//获取学生列表
			studentLists() {
				return new Promise((resolve, reject) => {
					var params = {
						name: this.studentName
					}
					listStudents(params).then(res => {
						if (res.code == 0) {
							if (res.data && res.data.length > 0) {
								this.studentList = res.data
								if (this.$route.query.studentId) {
									this.studentId = this.$route.query.studentId
									this.name = res.data.filter(item => item.studentAccountId == this
										.studentId)[0].name
									this.studentIndex = res.data.findIndex(item => item.studentAccountId ==
										this.studentId)
									storage.set('studentIndex', this.studentIndex)
									if (this.studentList[this.studentIndex].updateNotifyId) {
										this.studentUpdateClear({
											updateNotifyId: this.studentList[this.studentIndex]
												.updateNotifyId
										}).then((res) => {
											if (res.code == 0) {
												this.studentList[this.studentIndex]
													.updateNotifyId = undefined
												this.$forceUpdate()
											}
										})
									}
								} else {
									if(storage.get('studentId')) {
										let Index = res.data.findIndex(item => item.studentAccountId == storage.get('studentId'))
										// console.log(Index,Index)
										if(Index==-1){
											this.studentIndex = 0
											this.studentId = res.data[this.studentIndex].studentAccountId
										}else{
											this.studentId = storage.get('studentId')
											this.studentIndex = res.data.findIndex(item => item.studentAccountId == this.studentId)
										}
									}else{
											if(storage.get('studentIndex')) {
												this.studentIndex = storage.get('studentIndex') * 1
											}
											this.studentId = res.data[this.studentIndex].studentAccountId;
									}
									// if (storage.get('studentIndex')) {
									// 	this.studentIndex = storage.get('studentIndex') * 1
									// }
									// this.studentId = res.data[this.studentIndex].studentAccountId
									this.name = res.data[this.studentIndex].name
									if (this.studentList[this.studentIndex].updateNotifyId) {
										this.studentUpdateClear({
											updateNotifyId: this.studentList[this.studentIndex]
												.updateNotifyId
										}).then((res) => {
											if (res.code == 0) {
												this.studentList[this.studentIndex]
													.updateNotifyId = undefined
												this.$forceUpdate()
											}
										})
									}
								}
								resolve(this.studentList)
								resolve(this.studentId)
							}
						} else {
							reject()
						}
					})
				})
			},
			selectStudent(props) {
				this.studentId = props.studentAccountId
				this.studentIndex = this.studentList.findIndex(item => item.studentAccountId == this.studentId)
				storage.set('studentIndex', this.studentIndex)
				storage.set('studentId', this.studentId)
				this.differentTabFn()
			},
			searchStudent(data) {
				this.studentName = data
				this.studentLists()
			},
			changeTab(props) {
				this.currentTab = props.label
				storage.set('currentTab', this.currentTab, 7 * 24 * 60 * 60 * 1000)
				this.differentTabFn()
				this.$router.push({
					query: {}
				});
			},
			addZero(num) {
				if (num < 10)
					return "0" + num;
				return num;
			},
			differentTabFn() {
				let _this = this
				if (!_this.studentId) return
				switch (_this.currentTab) {
					case "总视图":
						_this.$nextTick(() => {
							_this.$refs.allViews.getCurrentDate()
						})
						break;
					case "月视图":
						_this.$nextTick(() => {
							_this.$refs.monthViews.getCurrentDate()
						})
						break;
					case "任务发放记录":
						_this.$nextTick(() => {
							_this.$refs.grantHistory.taskGrantHistory()
						})

						break;
				}
			},
			targetDetailHandle(props) {
				// console.log("targetDetailHandle", props)
				this.$nextTick(() => {
					this.$refs.taskForm.getTaskData("targetDetailBtn", props)
				})
			},
			allviewTaskHandle(props) {
				this.$nextTick(() => {
					this.$refs.taskForm.getTaskData("allViewBtn", props)
				})
			},
			monthviewTaskHandle(props) {
				// console.log("monthviewTaskHandle", props)
				this.$nextTick(() => {
					this.$refs.taskForm.getTaskData("monthViewBtn", props)
				})
			},
			handleFn(type) {
				switch (type) {
					case "task":
						let nowTime = storage.get('monthViewsDate')
						let params = {}
						if (nowTime) {
							params.year = new Date(nowTime).getFullYear()
							params.month = this.addZero(new Date(nowTime).getMonth() + 1)
						}
						this.$nextTick(() => {
							this.$refs.taskForm.getTaskData("monthViewIcon", params)
						})
						break;
					case "summary":
						this.$nextTick(() => {
							this.$refs.summaryForm.getSummaryData()
						})
						break;
				}
			},
			enterBtn() {
				this.showBtn = true
			},
			outBtn() {
				this.showBtn = false
			},
		},
	}
</script>
<style lang="scss" scoped>
	@import "../../styles/index.scss";

	.taskProgress {
		display: flex;
		flex-direction: column;

		.taskProgress_tab {
			padding-left: 628px;
			background-color: #F7F7F7;
			margin-top: 4px;
		}

		.taskProgress_content {
			flex: 1;
			background-color: #ffffff;
			padding: 20px 40px;
			display: flex;

			.taskProgress_left {
				margin-right: 20px;
			}

			.taskProgress_right {
				flex: 1;

				.taskProgress_right_allview {}

				.taskProgress_right_monthview {}

				.taskProgress_right_history {
					position: relative;
				}
			}
		}

		.taskProgress_btn {
			width: 50px;
			display: flex;
			flex-direction: column;
			position: fixed;
			bottom: 40px;
			right: 0px;
			z-index: 500;
			cursor: pointer;
			transition: all 0.6s;

			.small_img {
				width: 50px;
				height: 50px;
				margin: 15px 0;
				transition: all 0.6s;
			}

			.big_img {
				width: 80px;
				height: 80px;
				margin: 20px 0;
				cursor: pointer;
			}
		}
		.taskProgress_btn_right {
			width: 120px;
		}
	}
</style>
