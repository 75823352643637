<template>
	<div class="allviewTable">
		<div class="allviewTable_content">
			<div class="table_box" v-if="showViews">
				<!-- 绘制日历 -->
				<div class="table_left">
					<div v-for="(itemOne,indexOne) in allviewData" :key="indexOne" class="table_left_inner">
						<div class="table_left_strategy">
							<!-- 日期选择 -->
							<div class="flex_between" v-if="itemOne.tableHead">
								<div class="left_strategy_date">
									<div class="flex_item">
										<span>{{currentData.currentYear}}</span>
										<img src="@/assets/images/dashboard/moreArrow.png">
									</div>
									<el-date-picker @change="dateSelect" v-model="currentData.currentDate"
										class="strategy_date_inner" type="year" placeholder="选择年">
									</el-date-picker>
									<img @click.stop="allviewTableHandle('strategyAdd', {...itemOne, 'firstIndex': indexOne})"
										src="@/assets/images/taskProgress/add.png" class="eft_strategy_add">
								</div>
							</div>

							<!-- 策略 -->
							<div v-else @mouseenter="editCurrentTarget('strategy', itemOne)"
								@mouseleave="outCurrentTarget('strategy', itemOne)"
								:class="itemOne.theme? 'allviewTable_bg_'+ itemOne.theme : ''"
								class="left_strategy_title">
								<span v-if="!itemOne.isShow"
									:class="itemOne.theme? 'allviewTable_font_'+ itemOne.theme : ''"
									class="strategy_title_value">{{itemOne.name}}</span>
								<div v-if="itemOne.isShow" class="strategy_item_shadow">
									<img @click.stop="allviewTableHandle('classifyAdd', {...itemOne, 'firstIndex': indexOne})"
										src="@/assets/images/taskProgress/add_light.png">
									<!-- <div class="item_shadow_color">
										<img class="margin_16"
											@click.stop="allviewTableHandle('strategyColor', itemOne)"
											src="@/assets/images/taskProgress/color_light.png">
										<div v-if="itemOne.showColor" class="table_selectcolor selectcolor_bottom30">
											<div @click="allviewTableHandle('strategyTheme', {...itemOne, ...itemColor, 'firstIndex': indexOne})"
												v-for="(itemColor,indexColor) in colorList" :key="indexColor"
												class="table_selectcolor_item"
												:class="itemOne.theme&&itemOne.theme == itemColor.theme? 'allviewTable_border_'+ itemOne.theme : ''">
												<span :style="{'background-color': itemColor.color}"></span>
											</div>
										</div>
									</div> -->
									<img class="margin_16"
										@click.stop="allviewTableHandle('strategyEdit', {...itemOne, 'firstIndex': indexOne})"
										src="@/assets/images/taskProgress/edit_light.png">
									<img @click.stop="allviewTableHandle('strategyDelete', {...itemOne, 'firstIndex': indexOne})"
										src="@/assets/images/taskProgress/delete_light.png">
								</div>
							</div>
						</div>
						<!-- 分类-头部 -->
						<div v-if="itemOne.tableHead" style="background: #F7F7F7;" class="table_left_classify">
							<div class="left_classify_list">
								<span class="left_classify_item table_row1">分类</span>
							</div>
						</div>
						<!-- 分类-类名 -->
						<div v-else class="table_left_classify">
							<div class="left_classify_list">
								<div @mouseenter="editCurrentTarget('classify', itemTwo)"
									@mouseleave="outCurrentTarget('classify', itemTwo)"
									v-for="(itemTwo, indexTwo) in itemOne.classify" :key="indexTwo">
									<!-- 添加分类 -->
									<span style="cursor: pointer;"
										@click.stop="allviewTableHandle('classifyAdd', {...itemOne, 'firstIndex': indexOne})"
										:class="['table_row'+itemTwo.row,'allviewTable_'+ itemTwo.colour]"
										class="left_classify_item"
										v-if="!itemTwo.isShow&&!itemTwo.viewClassId">{{itemTwo.className}}</span>
									<!-- 已有分类 -->
									<span :class="['table_row'+itemTwo.row,'allviewTable_'+ itemTwo.colour]"
										class="left_classify_item"
										v-if="!itemTwo.isShow&&itemTwo.viewClassId">{{itemTwo.className}}</span>
									<div v-if="itemTwo.isShow&&itemTwo.viewClassId" class="classify_item_shadow"
										:class="'table_row'+itemTwo.row">
										<div class="classify_item_inner">
											<img class="margin_right_16"
												@click.stop="allviewTableHandle('targetAdd', {...itemTwo, 'firstIndex': indexOne, 'secondIndex': indexTwo})"
												src="@/assets/images/taskProgress/add_light.png">
											<!-- <img class="margin_right_16"
												@click.stop="allviewTableHandle('classifyColor', itemTwo)"
												src="@/assets/images/taskProgress/color_light.png"> -->
											<img class="margin_right_16"
												@click.stop="allviewTableHandle('classifyEdit', {...itemTwo, 'firstIndex': indexOne, 'secondIndex': indexTwo})"
												src="@/assets/images/taskProgress/edit_light.png">
											<img @click.stop="allviewTableHandle('classifyDelete', itemTwo)"
												src="@/assets/images/taskProgress/delete_light.png">
											<!-- <div class="item_shadow_color">
												<div v-if="itemTwo.showColor"
													class="table_selectcolor selectcolor_bottom30">
													<div @click="allviewTableHandle('classifyTheme', {...itemTwo, ...itemColor, 'firstIndex': indexOne, 'secondIndex': indexTwo})"
														v-for="(itemColor,indexColor) in colorList" :key="indexColor"
														class="table_selectcolor_item"
														:class="itemTwo.colour&&itemTwo.colour == itemColor.theme? 'allviewTable_border_'+ itemTwo.colour : ''">
														<span :style="{'background-color': itemColor.color}"></span>
													</div>
												</div>
											</div> -->
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="table_right">
					<swiper class="table_right_swiper" :options="swiperOption" ref="mySwiper">
						<swiper-slide v-for="(itemOne,indexOne) in currentData.months" :key="indexOne">
							<!-- 头部日期 -->
							<div style="background: #F7F7F7;" class="right_swiper_value">{{itemOne}}</div>
							<div class="" v-for="(itemTwo,indexTwo) in allviewData" :key="indexTwo">
								<div v-if="!itemTwo.tableHead" class="">
									<div v-for="(itemThree,indexThree) in itemTwo.classify" :key="indexThree" class="">
										<div v-for="(itemFour,indexFour) in itemThree.row" :key="indexFour"
											class="right_swiper_grid">
											<div @mouseenter="editCurrentTarget('target', itemFive)"
												@mouseleave="outCurrentTarget('target', itemFive)"
												class="right_swiper_tag"
												:class="{'z_index_2': (!targetColorDetail.showColor&&!targetColorDetail.targetId) || (targetColorDetail.showColor&&targetColorDetail.targetId == itemFive.viewTargetId)}"
												v-for="(itemFive,indexFive) in itemThree.targetList" :key="indexFive">
												<div @click="allviewTableHandle('targetDetail', itemFive)"
													class="tag_shadow_bg" :class="'tag_shadow_' + itemFive.delay"
													v-if="itemFive.isShow&&itemFive.viewClassId == itemThree.viewClassId&&itemFive.num == indexOne+1&&itemFive.row == indexFour+1">
													<!-- <img class="margin_16"
														@click.stop="allviewTableHandle('targetTaskAdd', itemFive)"
														src="@/assets/images/taskProgress/add_light.png"> -->
													<img @click.stop="allviewTableHandle('targetEdit', {...itemFive, 'targetList': itemThree.targetList})"
														src="@/assets/images/taskProgress/edit_light.png">
													<div class="tag_shadow_color">
														<img @click.stop="allviewTableHandle('targetColor', itemFive)"
															src="@/assets/images/taskProgress/color_light.png">
														<div v-if="itemFive.showColor"
															class="table_selectcolor selectcolor_bottom30" :style="{'right': itemFive.num >= 11 ? '0px': '-200px'}">
															<div class="table_selectcolor_btn">
																<span>颜色</span>
																<img @click="allviewTableHandle('targetTheme', {...itemFive, 'freshTheme': 1, 'firstIndex': indexTwo, 'secondIndex': indexThree,})"
																	src="@/assets/images/taskProgress/fresh.png">
															</div>
															<div class="table_selectcolor_list">
																<div @click="allviewTableHandle('targetTheme', {...itemFive, ...itemColor, 'firstIndex': indexTwo, 'secondIndex': indexThree,})"
																	v-for="(itemColor,indexColor) in colorList"
																	:key="indexColor" class="table_selectcolor_item"
																	:class="itemFive.colour&&itemFive.colour == itemColor.theme? 'allviewTable_border_'+ itemFive.colour : ''">
																	<span
																		:style="{'background-color': itemColor.color}"></span>
																</div>
															</div>
														</div>
													</div>
													<img @click.stop="allviewTableHandle('targetDelete', itemFive)"
														src="@/assets/images/taskProgress/delete_light.png">
												</div>
												<div class="swiper_tag_bg"
													:class="['swiper_tag_' + itemFive.delay, 'allviewTable_'+ itemFive.colour]"
													v-if="!itemFive.isShow&&itemFive.viewClassId == itemThree.viewClassId&&itemFive.num == indexOne+1&&itemFive.row == indexFour+1">
													{{itemFive.title}}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</swiper-slide>
					</swiper>
				</div>
			</div>
			<div v-if="!showViews" class="table_empty" element-loading-spinner="el-icon-loading"
				element-loading-text="数据加载中~" v-loading.lock="!showViews">
				<!-- <img src="@/assets/images/common/empty1.png">
				<span>暂无总视图数据</span> -->
			</div>
		</div>
		<messege-box ref="messegeBox" @confirmMessege="confirmMessege" @closeMessege="closeMessege"
			:isOpen="messegeVisible" :messegeTip="messegeTip" :messegeContent="messegeContent"
			:messegeType="messegeType" />
	</div>
</template>

<script>
	import {
		swiper,
		swiperSlide
	} from "vue-awesome-swiper";
	import "swiper/dist/css/swiper.css";
	import Bus from "./eventBus.js"
	import {
		strategyAllViews,
		editAllViewsStrategy,
		classifyAllViews,
		targetAllViews,
		editAllViewsClassify,
		editAllViewsTarget,
		deleteAllViewsTarget,
		deleteAllViewsClassify,
		deleteAllViewsStrategy,
	} from "@/api/taskProgress"
	import storage from 'store'
	export default {
		components: {
			swiper,
			swiperSlide
		},
		props: {
			studentId: {
				type: String,
				default: function() {
					return ""
				}
			}
		},
		data() {
			return {
				currentData: {
					currentDate: null,
					currentYear: null,
					classifyName: "分类",
					months: [],
					tableHead: true
				},
				allviewData: [],
				swiperOption: {
					autoplay: false,
					freeMode: true, // 随意拖动位置
					mousewheel: false, // 鼠标滚动滚动
					slidesPerView: 6,
				},
				clickTime1: 0,
				clickTime2: 0,
				colorList: [{
						color: "#5BA897",
						theme: "theme1"
					},
					{
						color: "#D0743B",
						theme: "theme2"
					},
					{
						color: "#B39A77",
						theme: "theme3"
					},
					{
						color: "#B5B181",
						theme: "theme4"
					},
					{
						color: "#99A89C",
						theme: "theme5"
					},
					{
						color: "#D03B3B",
						theme: "theme6"
					},
					{
						color: "#4690FC",
						theme: "theme7"
					},
					{
						color: "#7285F9",
						theme: "theme8"
					},
					{
						color: "#FEBD04",
						theme: "theme9"
					},
					{
						color: "#333333",
						theme: "theme10"
					},
				],
				messegeId: "",
				messegeVisible: false,
				messegeTip: "",
				messegeContent: "",
				messegeType: "",
				targetColorDetail: {
					showColor: false,
					targetId: undefined
				},
				showViews: true
			}
		},
		mounted() {
			// Bus.$on("titleFormHandle", props => {
			// 	this.getAllviewDate()
			// })
		},
		created() {
			Bus.$on("titleFormHandle", props => {
				console.log("titleFormHandle", props, this.currentData)
				let nowTime = storage.get('allViewsDate')
				switch (props.handleType) {
					case "strategyEdit":
						this.allviewData[props.currentFirst].name = props.strategyName
						this.$forceUpdate()
						break;
					case "classifyEdit":
						this.allviewData[props.currentFirst].classify[props.currentSecond].className = props
							.classifyName
						break;
					case "classifyAdd":
						if (nowTime) {
							this.getCurrentDate(nowTime)
						}
						break;
					case "strategyAdd":
						if (nowTime) {
							this.getCurrentDate(nowTime)
						}
						break;
				}
			})
			Bus.$on("targetFormHandle", props => {
				let nowTime = storage.get('allViewsDate')
				console.log("targetFormHandle", props, nowTime)
				switch (props.handleType) {
					case "targetAdd":
						if (nowTime) {
							this.getCurrentDate(nowTime)
						}
						break;
					case "targetEdit":
						if (nowTime) {
							this.getCurrentDate(nowTime)
						}
						break;
				}
			})
		},
		methods: {
			// 获取当前年月
			async getCurrentDate(intDate) {
				this.targetColorDetail = {
					showColor: false,
					targetId: undefined
				}
				let nowTime = null
				if (intDate) {
					nowTime = new Date(intDate)
				} else {
					nowTime = new Date()
				}
				let nowYear = nowTime.getFullYear()
				let nowMonths = []
				for (var i = 1; i <= 12; i++) {
					let monthItem = `${nowYear}-${i}`
					nowMonths.push(monthItem)
				}
				// console.log("当前年月", nowMonths)
				this.currentData.currentDate = nowTime
				this.currentData.currentYear = nowYear
				this.currentData.months = nowMonths
				storage.set('allViewsDate', nowTime, 7 * 24 * 60 * 60 * 1000)
				await this.getAllviewDate()
				this.allviewData.unshift(this.currentData)
				this.showViews = true
			},
			// 获取两个时间段中的间隔月数
			getMonthInterval(startDate, endDate) {
				let end = endDate.split('-'); //例：将2020-12-21 的-去掉
				end = parseInt(end[0]) * 12 + parseInt(end[1]); //将字符串转换为数字格式
				let start = startDate.split('-');
				start = parseInt(start[0]) * 12 + parseInt(start[1]);
				let interval = (end - start) * 1 + 1
				// console.log("间隔月数", interval)
				return interval
			},
			dateSelect(value) {
				console.log("dateSelect", value)
				storage.set('allViewsDate', value, 7 * 24 * 60 * 60 * 1000)
				this.getCurrentDate(value)
			},
			// 筛选共同项
			filterCommon(intArr1, intArr2) {
				// console.log("filterCommon", intArr1, intArr2)
				let resultArr = intArr1.filter(item => intArr2.some(ele => ele.viewClassId == item.viewClassId))
				return resultArr
			},
			// 获取相同开始日期出现最多次数
			commonDateNum(intArr) {
				var arrMap = new Map()
				let key = intArr[0].completeDateStart
				let value = 1
				intArr.forEach(item => {
					if (arrMap.get(item.completeDateStart) !== undefined) {
						let num = arrMap.get(item.completeDateStart)
						arrMap.set(item.completeDateStart, ++num)
					} else {
						arrMap.set(item.completeDateStart, 1)
					}
					if (arrMap.get(item.completeDateStart) > value) {
						key = item.completeDateStart
						value = arrMap.get(item.completeDateStart)
					}
				})
				// console.log(key + '打印了' + value + '次')
				return value
			},
			// 对数组中的相同元素进行标记
			// signArr(intArr) {
			// 	let originArr = JSON.parse(JSON.stringify(intArr))
			// 	// console.log("originArr", originArr)
			// 	let newArr = []
			// 	let mapArr = []
			// 	let resultArr = []
			// 	originArr.map((item, index) => {
			// 		if (mapArr.indexOf(item.completeDateStart) === -1) {
			// 			newArr.push({
			// 				commomStart: item.completeDateStart,
			// 				commonArr: []
			// 			});
			// 			mapArr.push(item.completeDateStart)
			// 		}
			// 	});
			// 	newArr.map(item => {
			// 		originArr.map(items => {
			// 			if (item.commomStart == items.completeDateStart) {
			// 				item.commonArr.push(items)
			// 			}
			// 		})
			// 	})
			// 	// console.log("newArr", newArr)
			// 	if (newArr.length > 0) {
			// 		newArr.forEach(itemNew => {
			// 			if (itemNew.commonArr && itemNew.commonArr.length > 0) {
			// 				itemNew.commonArr.forEach((itemCommon, indexCommon) => {
			// 					let newObj = {
			// 						...itemCommon,
			// 						row: indexCommon + 1
			// 					}
			// 					resultArr.push(newObj)
			// 				})
			// 			}
			// 		})
			// 	}
			// 	// console.log("resultArr", resultArr)
			// 	resultArr.forEach(itemResult => {
			// 		let newDate = new Date(itemResult.completeDateStart)
			// 		this.$set(itemResult, "isShow", false)
			// 		this.$set(itemResult, "showColor", false)
			// 		this.$set(itemResult, "num", newDate.getMonth() + 1)
			// 		let newDelay = this.getMonthInterval(itemResult.completeDateStart, itemResult
			// 			.completeDateEnd)
			// 		this.$set(itemResult, "delay", newDelay)
			// 	})
			// 	// console.log("signArr", resultArr)
			// 	return resultArr
			// },
			signArr(intArr) {
				let originArr = this.extendArr(intArr)
				// console.log("originArr", originArr)
				let newArr = []
				let mapArr = []
				let resultArr = []
				originArr.map((item, index) => {
					if (mapArr.indexOf(item.completeDateStart) === -1) {
						newArr.push({
							commomStart: item.completeDateStart,
							commonArr: []
						});
						mapArr.push(item.completeDateStart)
					}
				});
				newArr.map(item => {
					originArr.map(items => {
						if (item.commomStart == items.completeDateStart) {
							item.commonArr.push(items)
						}
					})
				})
				// console.log("newArr", newArr)
				if (newArr.length > 0) {
					newArr.forEach(itemNew => {
						if (itemNew.commonArr && itemNew.commonArr.length > 0) {
							itemNew.commonArr.forEach((itemCommon, indexCommon) => {
								let newObj = {
									...itemCommon,
									row: indexCommon + 1
								}
								resultArr.push(newObj)
							})
						}
					})
				}
				// console.log("resultArr", resultArr)
				resultArr.forEach((itemResult, indexResult) => {
					let newDate = new Date(itemResult.completeDateStart)
					this.$set(itemResult, "isShow", false)
					this.$set(itemResult, "showColor", false)
					this.$set(itemResult, "num", newDate.getMonth() + 1)
					if (itemResult.completeDateEnd) {
						let newDelay = this.getMonthInterval(itemResult.completeDateStart, itemResult
							.completeDateEnd)
						this.$set(itemResult, "delay", newDelay)
					}
				})
				// let newResult = resultArr.filter(item => item.id != 'empty')
				// console.log("newResult", newResult)
				return resultArr
			},
			extendArr(intArr) {
				let newArr = JSON.parse(JSON.stringify(intArr))
				let newList = []
				newArr.map((itemOne, indexOne) => {
					newList.push(itemOne)
					let monthDelay = this.getDateBetweenMonth(itemOne.completeDateStart, itemOne
						.completeDateEnd)
					this.$set(itemOne, "monthDelay", monthDelay)
					if (monthDelay.length > 1) {
						monthDelay.forEach(itemTwo => {
							if (itemTwo != itemOne.completeDateStart) {
								newList.push({
									completeDateStart: itemTwo,
									id: "empty"
								})
							}
						})
					}
				})
				return newList
			},
			// 截取当年任务
			getNowYearTask(intTask) {
				return new Promise((resolve, reject) => {
					if (intTask && intTask.length > 0) {
						intTask.forEach(item => {
							this.$set(item, "completeDateStartOld", item.completeDateStart)
							this.$set(item, "completeDateEndOld", item.completeDateEnd)
							let startYear = new Date(item.completeDateStart).getFullYear()
							let endYear = new Date(item.completeDateEnd).getFullYear()
							let startMonth = new Date(item.completeDateStart).getMonth() + 1
							let endMonth = new Date(item.completeDateEnd).getMonth() + 1
							if (startYear != this.currentData.currentYear) {
								item.completeDateStart = this.currentData.currentYear + '-' + '01-01'
							}
							if (endYear != this.currentData.currentYear) {
								item.completeDateEnd = this.currentData.currentYear + '-' + '12-31'
							}
						})
						// console.log("getNowYearTask", intTask)
						resolve(intTask)
					} else {
						reject()
					}
				})
			},
			addZero(num) {
				if (num < 10)
					return "0" + num;
				return num;
			},
			// 获取日期之间的月份
			getDateBetweenMonth(start, end) {
				let result = [];
				let s = start.split("-");
				let e = end.split("-");
				let min = new Date();
				let max = new Date();
				min.setFullYear(s[0], s[1] * 1 - 1, 1); //开始日期
				max.setFullYear(e[0], e[1] * 1 - 1, 1); //结束日期
				let curr = min;
				while (curr <= max) {
					let year = curr.getFullYear()
					let month = curr.getMonth();
					// console.log(month + 1);
					result.push(year + '-' + this.addZero(month + 1) + '-01');
					curr.setMonth(month + 1);
				}
				// console.log("日期间隔月份", result)
				return result;
			},
			// 判断数组中的元素是否相同元素
			checkArrCommon(intArr1, intArr2) {
				let newArr1 = []
				let newArr2 = []
				for (var i = 0; i < intArr2.length; i++) {
					if (!newArr1.hasOwnProperty(newArr1[i])) {
						newArr1[intArr2[i]] = true;
					}
				}
				for (var i = 0; i < intArr1.length; i++) {
					if (!newArr1[intArr1[i]]) {
						newArr2.push(intArr1[i]); //过滤intArr1 中与intArr2 相同的元素；
					}
				}
				// console.log("checkArrCommon", newArr2)
				return newArr2
			},
			// 数组根据ID排序
			sortArrWith(property) {
				return function(a, b) {
					var sort1 = a[property] * 1;
					var sort2 = b[property] * 1;
					return sort1 - sort2
				}
			},
			// 获取总视图目标
			allViewsTarget() {
				return new Promise((resolve, reject) => {
					let params = {
						studentInfoId: this.studentId,
						year: this.currentData.currentYear,
					}
					targetAllViews(params).then(res => {
						if (res.code == 0) {
							let targetArr = []
							if (res.data && res.data.length > 0) {
								targetArr = res.data
								resolve(targetArr)
							} else {
								resolve(targetArr)
							}
						} else {
							reject()
						}
					})
				})
			},
			// 获取总视图策略数据
			allViewsStrategy() {
				return new Promise((resolve, reject) => {
					let params = {
						studentInfoId: this.studentId,
					}
					strategyAllViews(params).then((res) => {
						if (res.code == 0) {
							let strategys = []
							if (res.data && res.data.length > 0) {
								strategys = res.data.sort(this.sortArrWith("viewStrategyId"))
								resolve(strategys)
							} else {
								resolve(strategys)
							}
						} else {
							this.showViews = true
							reject()
						}
					})
				})
			},
			// 获取总视图分类数据
			async allViewsClassify() {
				return new Promise((resolve, reject) => {
					let params = {
						studentInfoId: this.studentId,
						year: this.currentData.currentYear,
					}
					classifyAllViews(params).then(async res => {
						if (res.code == 0) {
							if (res.data && res.data.length > 0) {
								let resData = res.data
								// console.log("获取总视图数据-分类数据1", resData)
								resData.forEach(async item => {
									this.$set(item, "isShow", false)
									this.$set(item, "showColor", false)
									if (item.targetList && item.targetList.length >
										0) {
										// console.log("获取总视图数据-分类数据111", item)
										item.targetList = await this.getNowYearTask(
											item
											.targetList)
										item.targetList = this.signArr(item.targetList)
										let commonDateNum = this.commonDateNum(item
											.targetList)
										this.$set(item, "row", commonDateNum)
									} else {
										this.$set(item, "row", 1)
									}
								})
								resolve(resData)
							} else {
								this.showViews = true
								resolve([])
							}
						} else {
							this.showViews = true
							reject()
						}
					})
				})
			},
			// 将分类按照相同策略id分组
			async groupIntoClassify(intData) {
				return new Promise((resolve, reject) => {
					let resultArr = []
					let hasStrategyId = intData.filter(item => {
						return item.viewStrategyId
					})
					// console.log("groupIntoClassify1", hasStrategyId)
					if (hasStrategyId && hasStrategyId.length > 0) {
						let keys = hasStrategyId.map((item) => {
							if (item.viewStrategyId) {
								return item.viewStrategyId * 1;
							}
						});
						keys = [...new Set(keys)].sort((a, b) => parseInt(a*1) - parseInt(b*1));
						console.log("groupIntoClassify2", keys)
						let result = {};
						keys.forEach((item) => {
							result[item] = [];
						});
						hasStrategyId.map((item) => {
							keys.some((value) => {
								if (item.viewStrategyId == value) {
									result[value].push(item);
									return;
								}
							});
						});
						// keys.forEach(item => {
						// 	// 分类ID排序
						// 	result[item] = result[item].sort(this.sortArrWith("viewClassId"))
						// })
						console.log("groupIntoClassify3", result)
						resolve(result)
					} else {
						this.showViews = true
						resolve([])
					}
				})
			},
			// 获取总视图数据
			async getAllviewDate() {
				this.showViews = false
				let strategy = await this.allViewsStrategy()
				console.log("获取总视图数据-策略数据", strategy)
				let classifys = await this.allViewsClassify()
				console.log("获取总视图数据-分类数据2", classifys)
				let groupIntoClass = await this.groupIntoClassify(classifys)
				// console.log("获取总视图数据-分类数据分类", groupIntoClass)
				return new Promise((resolve, reject) => {
					this.allviewData = []
					if (strategy && strategy.length > 0) {
						strategy.forEach(item => {
							if (groupIntoClass[item.viewStrategyId]) {
								this.allviewData.push({
									viewStrategyId: item.viewStrategyId ? item.viewStrategyId :
										"",
									theme: item.colour ? item.colour : "",
									name: item.strategyName,
									showColor: false,
									isShow: false,
									classify: groupIntoClass[item.viewStrategyId]
								})
							} else {
								let classifyEmpty = {
									className: "暂无分类",
									row: 1,
									viewStrategyId: item.viewStrategyId,
									studentId: this.studentId,
									year: this.currentData.currentYear
								}
								this.allviewData.push({
									viewStrategyId: item.viewStrategyId ? item.viewStrategyId :
										"",
									theme: item.colour ? item.colour : "",
									name: item.strategyName,
									showColor: false,
									isShow: false,
									classify: [classifyEmpty]
								})
							}
						})
					}
					console.log("获取总视图数据-最终数据", this.allviewData)
					this.showViews = true
					resolve(this.allviewData)
				})
			},
			editCurrentTarget(type, item) {
				switch (type) {
					case "classify":
						item.isShow = true
						break;
					case "target":
						item.isShow = true
						break;
					case "strategy":
						item.isShow = true
						break;
				}
			},
			outCurrentTarget(type, item) {
				switch (type) {
					case "classify":
						item.isShow = false
						item.showColor = false
						break;
					case "target":
						item.isShow = false
						item.showColor = false
						this.targetColorDetail = {
							showColor: false,
							targetId: undefined
						}
						break;
					case "strategy":
						item.isShow = false
						item.showColor = false
						break;
				}
			},
			closeMessege() {
				this.messegeVisible = false;
				this.messegeId = "";
			},
			confirmMessege() {
				let nowTime = storage.get('allViewsDate')
				switch (this.messegeTip) {
					case "删除策略":
						deleteAllViewsStrategy({
							viewStrategyId: this.messegeId
						}).then((res) => {
							if (res.code == 0) {
								this.$message({
									message: "策略删除成功~",
									type: 'success'
								})
								this.getCurrentDate(nowTime)
								this.messegeVisible = false
							}
						})
						break;
					case "删除目标":
						deleteAllViewsTarget({
							viewTargetId: this.messegeId
						}).then((res) => {
							if (res.code == 0) {
								this.$message({
									message: "目标删除成功~",
									type: 'success'
								})
								this.getCurrentDate(nowTime)
								this.messegeVisible = false
							}
						})

						break;
					case "删除分类":
						deleteAllViewsClassify({
							viewClassId: this.messegeId
						}).then((res) => {
							if (res.code == 0) {
								this.$message({
									message: "分类删除成功~",
									type: 'success'
								})
								this.getCurrentDate(nowTime)
								this.messegeVisible = false
							}
						})

						break;
				}
			},
			allviewTableHandle(type, item) {
				if (type == "targetDetail") {
					try {
						if (this.clickTime1 == 0) {
							this.clickTime1 = new Date().getTime()
							setTimeout(() => {
								if (this.clickTime2 == 0) {
									console.log('单击事件')
									this.clickTime1 = 0
								}
							}, 310)
						} else {
							this.clickTime2 = new Date().getTime()
							if (this.clickTime2 - this.clickTime1 <= 300) {
								console.log('这是双击')
								Bus.$emit("allviewTableHandle", {
									year: this.currentData.currentYear,
									studentId: this.studentId,
									handleType: type,
									...item
								})
							}
							this.clickTime1 = 0
							setTimeout(() => {
								this.clickTime2 = 0
							}, 320)
						}
					} catch (e) {
						console.log(e)
					}
				} else if (type == "strategyColor") {
					item.showColor = !item.showColor
				} else if (type == "strategyDelete") {
					this.messegeId = item.viewStrategyId;
					this.messegeTip = "删除策略";
					this.messegeContent = "确定删除此策略吗?";
					this.messegeVisible = true;
					this.messegeType = "warn";
				} else if (type == "strategyTheme") {
					let params = {
						colour: item.theme,
						strategyName: item.name,
						strategyType: item.type,
						studentAccountId: this.studentId,
						viewStrategyId: item.viewStrategyId ? item.viewStrategyId : "",
					}
					console.log("strategyTheme", item, this.allviewData[item.firstIndex])
					editAllViewsStrategy(params).then((res) => {
						this.$message({
							message: "策略颜色修改成功~",
							type: 'success'
						})
						this.allviewData[item.firstIndex].theme = item.theme
						this.allviewData[item.firstIndex].showColor = false
						this.$forceUpdate()
					})
				} else if (type == "classifyColor") {
					item.showColor = !item.showColor
				} else if (type == 'classifyTheme') {
					console.log("classifyTheme", item)
					let paramsArr = ["className", "strategyType", "studentAccountId", "viewClassId", "year", ]
					let params = {}
					paramsArr.forEach(keyItem => {
						this.$set(params, keyItem, item[keyItem])
					})
					params.colour = item.theme
					editAllViewsClassify(params).then((res) => {
						this.$message({
							message: "分类颜色修改成功~",
							type: 'success'
						})
						this.allviewData[item.firstIndex].classify[item.secondIndex].colour = item.theme
						this.allviewData[item.firstIndex].classify[item.secondIndex].isShow = false
						this.allviewData[item.firstIndex].classify[item.secondIndex].showColor = false
						this.$forceUpdate()
					})
				} else if (type == "targetColor") {
					item.showColor = !item.showColor
					this.targetColorDetail = {
						showColor: true,
						targetId: item.viewTargetId
					}
					if (!item.showColor) {
						this.targetColorDetail = {
							showColor: false,
							targetId: undefined
						}
					}
					console.log("目标item", item)
				} else if (type == 'targetTheme') {
					let params = {
						attPath: item.attPath,
						completeDateStart: item.completeDateStartOld,
						completeDateEnd: item.completeDateEndOld,
						studentAccountId: item.studentAccountId,
						viewClassId: item.viewClassId,
						title: item.title,
						viewTargetId: item.viewTargetId,
						status: item.status,
					}
					if (item.freshTheme) {
						params.colour = ""
					} else {
						params.colour = item.theme
					}
					let targetArr = this.allviewData[item.firstIndex].classify[item.secondIndex].targetList
					let thirdIndex = targetArr.findIndex(itemTarget => itemTarget.viewTargetId == item.viewTargetId)
					console.log("targetTheme", thirdIndex)
					// console.log("目标颜色修改", params)
					editAllViewsTarget(params).then((res) => {
						if (res.code == 0) {
							if (item.freshTheme) {
								this.$message({
									message: "目标颜色重置成功~",
									type: 'success'
								})
								this.allviewData[item.firstIndex].classify[item.secondIndex].targetList[thirdIndex]
									.colour = ""
							} else {
								this.$message({
									message: "目标颜色修改成功~",
									type: 'success'
								})
								this.allviewData[item.firstIndex].classify[item.secondIndex].targetList[thirdIndex]
									.colour = item.theme
							}
							this.allviewData[item.firstIndex].classify[item.secondIndex].targetList[thirdIndex]
								.showColor = false
							this.allviewData[item.firstIndex].classify[item.secondIndex].targetList[thirdIndex]
								.isShow = false
						}
					})
				} else if (type == "classifyDelete") {
					console.log("删除分类", item)
					this.messegeId = item.viewClassId;
					this.messegeTip = "删除分类";
					this.messegeContent = "确定删除此分类吗?";
					this.messegeVisible = true;
					this.messegeType = "warn";
				} else if (type == "targetDelete") {
					console.log("删除目标", item)
					this.messegeId = item.viewTargetId;
					this.messegeTip = "删除目标";
					this.messegeContent = "确定删除此目标吗?";
					this.messegeVisible = true;
					this.messegeType = "warn";
				} else if (type == "targetTaskAdd") {
					this.$emit("allviewTaskHandle", {
						year: this.currentData.currentYear,
						studentId: this.studentId,
						handleType: type,
						...item
					})
				} else {
					Bus.$emit("allviewTableHandle", {
						year: this.currentData.currentYear,
						studentId: this.studentId,
						handleType: type,
						...item
					})
				}
			}
		},
	}
</script>

<style lang="scss" scoped="">
	@import "../../../styles/index.scss";
	@import "./common.scss";

	/deep/.swiper-slide {
		width: 198px !important;
	}

	/deep/.swiper-container {
		width: 1188px !important;
		margin: 0;
		padding: 0;
	}

	/deep/.left_strategy_date .el-input__inner {
		cursor: pointer;
	}

	.relative {
		position: relative;
	}

	.flex_item {
		display: flex;
		align-items: center;
	}

	.flex_between {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.swiper_tag_1,
	.tag_shadow_1 {
		width: 197px; // 全部都减了1，露出格子
	}

	.swiper_tag_2,
	.tag_shadow_2 {
		width: 395px;
	}

	.swiper_tag_3,
	.tag_shadow_3 {
		width: 593px;
	}

	.swiper_tag_4,
	.tag_shadow_4 {
		width: 791px;
	}

	.swiper_tag_5,
	.tag_shadow_5 {
		width: 989px;
	}

	.swiper_tag_6,
	.tag_shadow_6 {
		width: 1187px;
	}

	.swiper_tag_7,
	.tag_shadow_7 {
		width: 1382px;
	}

	.swiper_tag_8,
	.tag_shadow_8 {
		width: 1583px;
	}

	.swiper_tag_9,
	.tag_shadow_9 {
		width: 1781px;
	}

	.swiper_tag_10,
	.tag_shadow_10 {
		width: 1979px;
	}

	.swiper_tag_11,
	.tag_shadow_11 {
		width: 2177px;
	}

	.swiper_tag_12,
	.tag_shadow_12 {
		width: 2375px;
	}
</style>
