<template>
	<el-dialog :modal="false" class="dialog_bg" :append-to-body="false" :modal-append-to-body="false" @close="closeDialog" width="579px" :title="diaLogTitle" :visible.sync="diaLogVisible">
		<div class="handle_form">
			<el-form ref="targetForm" :rules="targetRules" :model="targetForm" label-width="70px">
				<div class="handle_form_row">
					<div class="handle_form_item">
						<el-form-item class="flex_item" prop="title" :label="'目标标题'">
							<input v-model="targetForm.title" class="handle_form_ipt" placeholder="请输入" />
						</el-form-item>
					</div>
				</div>
				<div class="handle_form_row">
					<div class="handle_form_item">
						<el-form-item prop="targetTime" class="flex_item" :label="'目标期限'">
							<div class="flex_item">
								<el-date-picker @blur="startSelect" :clearable="false" v-model="targetForm.startTime"
									class="handle_form_date" type="month" placeholder="选择月">
								</el-date-picker>
								<span class="handle_form_line"></span>
								<el-date-picker @blur="endSelect" :clearable="false" v-model="targetForm.endTime"
									class="handle_form_date" type="month" placeholder="选择月">
								</el-date-picker>
							</div>
						</el-form-item>
					</div>
				</div>
				<div class="handle_form_row">
					<div class="handle_form_item">
						<el-form-item label-width="70px" class="" label="附件:">
							<uploadFile @successUpload="successUpload" :fileSrc="targetForm.fileUrl" />
						</el-form-item>
					</div>
				</div>
				<div v-if="diaLogTitle == '目标标题编辑'" class="handle_form_row">
					<div class="handle_form_item">
						<el-form-item class="flex_item" prop="status" label="状态:">
							<el-select v-model="targetForm.status" class="handle_form_select" clearable
								placeholder="请选择">
								<el-option v-for="item in statusList" :key="item.value" :label="item.label"
									:value="item.value">
								</el-option>
							</el-select>
						</el-form-item>
					</div>
				</div>
			</el-form>
			<div class="handle_form_btn">
				<span @click="closeDialog" class="handle_form_cancel">取消</span>
				<span v-preventReClick @click="submitDialog" class="handle_form_confirm">确定</span>
			</div>
		</div>
	</el-dialog>
</template>

<script>
	import Bus from "./eventBus.js";
	import uploadFile from "@/components/uploadFile/index.vue";
	import commonFn from "@/utils/common.js";
	import {
		addAllViewsTarget,
		editAllViewsTarget,
	} from "@/api/taskProgress"
	export default {
		components: {
			uploadFile
		},
		data() {
			const checkTitle = (rule, value, callback) => {
				if (!value) {
					if (this.diaLogTitle == "目标标题编辑") {
						callback(new Error("请输入目标标题"));
					} else if (this.diaLogTitle == "目标标题添加") {
						callback(new Error("请输入目标标题"));
					}
				} else {
					callback();
				}
				callback();
			};
			const checkTime = (rule, value, callback) => {
				if (!value) {
					if (!this.targetForm.startTime || !this.targetForm.endTime) {
						callback(new Error("请选择目标期限"));
					}
				} else {
					callback();
				}
				callback();
			};
			return {
				diaLogTitle: "",
				diaLogVisible: false,
				targetForm: {},
				targetRules: {
					title: [{
						required: true,
						validator: checkTitle,
						trigger: 'change'
					}],
					targetTime: [{
						required: true,
						validator: checkTime,
						trigger: 'change'
					}],
					status: [{
						required: true,
						message: '请选择状态',
						trigger: 'change'
					}]
				},
				targetDates: [],
				statusList: [{
						label: "未完成",
						value: 1
					},
					{
						label: "已完成",
						value: 2
					}
				],
				propsData: {},
			}
		},
		mounted() {
			Bus.$on("allviewTableHandle", props => {
				console.log("allviewTableHandle996", props)
				this.targetForm = {}
				this.propsData = {}
				this.targetDates = []
				this.propsData = props
				if (props.handleType == "targetEdit") {
					let newDateArr = props.targetList.filter(item => item.id != 'empty' && item.viewTargetId != props.viewTargetId)
					newDateArr.forEach(item => {
						this.targetDates.push({
							start: item.completeDateStart,
							end: item.completeDateEnd
						})
					})
					this.targetForm = props
					if (this.targetForm.completeDateStartOld) {
						this.targetForm.startTime = new Date(this.targetForm.completeDateStartOld)

					}
					if (this.targetForm.completeDateEndOld) {
						this.targetForm.endTime = new Date(this.targetForm.completeDateEndOld)
					}
					if (this.targetForm.attPath) {
						this.targetForm.fileUrl = this.targetForm.attPath
					}
					this.diaLogTitle = "目标标题编辑"
					this.diaLogVisible = true
					console.log("targetDates-edit", this.targetDates)
				} else if (props.handleType == "targetAdd") {
					if (props.targetList) {
						let newDateArr = props.targetList.filter(item => item.id != 'empty')
						newDateArr.forEach(item => {
							this.targetDates.push({
								start: item.completeDateStart,
								end: item.completeDateEnd
							})
						})
					}
					console.log("targetDates-add", this.targetDates)
					this.diaLogTitle = "目标标题添加"
					this.diaLogVisible = true
				}
			})
		},
		methods: {
			// 时间选择
			startSelect() {
				// console.log("startSelect", this.targetForm.startTime)
				if (this.targetForm.startTime) {
					this.targetForm.startTime = commonFn.timeFormat(this.targetForm.startTime, "yyyy-MM-dd")
					this.targetForm.startTime = this.targetForm.startTime + " " + "00:00:00"
					this.$forceUpdate()
				}
			},
			endSelect() {
				if (this.targetForm.endTime) {
					let date = new Date(this.targetForm.endTime)
					let year = date.getFullYear()
					let month = date.getMonth() + 1
					let days = new Date(year, month, 0).getDate()
					this.targetForm.endTime = year + '-' + this.addZero(month) + '-' + this.addZero(days) + " " + "23:59:59"
					console.log("最后一天", this.targetForm.endTime)
					// this.targetForm.endTime = commonFn.timeFormat(this.targetForm.endTime, "yyyy-MM-dd")
					// this.targetForm.endTime = this.targetForm.endTime + " " + "00:00:00"
					this.$forceUpdate()
				}
			},
			successUpload(data) {
				this.targetForm.fileUrl = data.fileData
			},
			closeDialog() {
				this.diaLogVisible = false
				// this.$emit('closeDialog', 'commom')
			},
			//判断两个时间是否有交集
			isDateInterCommon(start1, end1, start2, end2) {
				var startdate1 = new Date(start1.replace("-", "/"));
				var enddate1 = new Date(end1.replace("-", "/"));
				var startdate2 = new Date(start2.replace("-", "/"));
				var enddate2 = new Date(end2.replace("-", "/"));
				if (startdate1 >= startdate2 && startdate1 <= enddate2) {
					return true;
				}
				if (enddate1 >= startdate2 && enddate1 <= enddate2) {
					return true;
				}
				if (startdate1 <= startdate2 && enddate1 >= enddate2) {
					return true;
				}
				return false;
			},
			isHasRepeatTime(data) {
				var startTimeArr = [];
				var endTimeArr = [];
				(data || []).map(function(item) {
					startTimeArr.push(item.start);
					endTimeArr.push(item.end);
				});
				var allStartTime = startTimeArr.sort();
				var allEndTime = endTimeArr.sort();
				var result = 0;
				for (var k = 1; k < allStartTime.length; k++) {
					if (allStartTime[k] <= allEndTime[k - 1]) {
						result += 1;
					}
				}
				return result > 0;
			},
			addZero(num) {
				if (num < 10)
					return "0" + num;
				return num;
			},
			submitDialog() {
				this.$refs["targetForm"].validate(valid => {
					if (valid) {
						let targetData = JSON.parse(JSON.stringify(this.targetForm))
						targetData.startTime = commonFn.timeFormat(targetData.startTime, "yyyy-MM-dd") + " " +
							'00:00:00'
						let endDate = new Date(targetData.endTime)
						let year = endDate.getFullYear()
						let month = endDate.getMonth() + 1
						let days = new Date(year, month, 0).getDate()
						targetData.endTime = year + '-' + this.addZero(month) + '-' + this.addZero(days) + " " + "23:59:59"
						let newDates = JSON.parse(JSON.stringify(this.targetDates))
						let num = 0
						console.log("目标标题添加", newDates)
						if (newDates && newDates.length > 0) {
							if (this.diaLogTitle == "目标标题添加") {
								newDates.forEach(item => {
									if (this.isDateInterCommon(item.start, item.end, targetData.startTime,
											targetData.endTime)) {
										num += 1
									}
								})
								// console.log("目标标题添加", num)
							} else {
								let targetStart = targetData.startTime.replace(" 00:00:00", "")
								let targetEnd = targetData.endTime.replace(" 23:59:59", "")
								if (newDates[0].start == targetStart && newDates[0]
									.end == targetEnd) {
									num = 0
								} else {
									newDates.forEach(item => {
										if (this.isDateInterCommon(item.start, item.end, targetData
												.startTime,
												targetData.endTime)) {
											num += 1
										}
									})
								}
								// console.log("目标标题编辑", newDates[0].start, targetStart, newDates[0]
								// 	.end, targetEnd)
							}
						}

						if (num > 0) {
							this.$message({
								message: "该日期范围内已存在其他目标~",
								type: 'error'
							})
							return
						}
						if (this.diaLogTitle == "目标标题添加") {
							let params = {
								colour: "",
								attPath: targetData.fileUrl,
								completeDateStart: targetData.startTime,
								completeDateEnd: targetData.endTime,
								studentAccountId: this.propsData.studentId,
								viewClassId: this.propsData.viewClassId,
								title: targetData.title,
							}
							console.log("目标标题添加", params)
							addAllViewsTarget(params).then((res) => {
								if (res.code == 0) {
									this.$message({
										message: "目标标题添加成功~",
										type: 'success'
									})
									Bus.$emit("targetFormHandle", {
										handleType: "targetAdd",
									})
									this.diaLogVisible = false
								}
							})
						} else if (this.diaLogTitle == "目标标题编辑") {
							let params = {
								colour: targetData.colour,
								attPath: targetData.fileUrl,
								completeDateStart: targetData.startTime,
								completeDateEnd: targetData.endTime,
								studentAccountId: this.propsData.studentId,
								viewClassId: this.propsData.viewClassId,
								title: targetData.title,
								viewTargetId: targetData.viewTargetId,
								status: targetData.status,
							}
							console.log("目标标题编辑", params)
							editAllViewsTarget(params).then((res) => {
								if (res.code == 0) {
									this.$message({
										message: "目标标题编辑成功~",
										type: 'success'
									})
									Bus.$emit("targetFormHandle", {
										handleType: "targetEdit",
									})
									this.diaLogVisible = false
								}
							})
						}
					}
				})
			},
		}
	}
</script>

<style lang="scss" scoped>
	@import "../../../styles/index.scss";

	/deep/.el-date-editor.el-input :focus {
		border: 1px solid $theme_color;
	}

	/deep/.el-input__inner {}

	input::-webkit-input-placeholder {
		font-size: 14px;
		font-family: Source Han Sans CN;
		font-weight: 400;
		line-height: 20px;
		color: #999999 !important;
	}

	textarea::-webkit-input-placeholder {
		font-size: 14px;
		font-family: Source Han Sans CN;
		font-weight: 400;
		line-height: 20px;
		color: #999999 !important;
	}

	.flex_item {
		display: flex;
		align-items: center;
	}

	.flex-column {
		display: flex;
		flex-direction: column;
	}

	.flex_between {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.handle_form {
		.handle_form_row {
			margin-top: 25px;
			display: flex;
			// padding: 0 23px;

			.handle_form_item {
				// flex: 1;
				display: flex;

				.handle_form_ipt {
					width: 407px;
					border: 1px solid #EEEEEE;
					outline: none;
					font-size: 14px;
					font-family: Source Han Sans CN;
					font-weight: 400;
					line-height: 40px;
					color: #000000;
					padding: 0 10px;
					box-sizing: border-box;
				}

				.handle_form_line {
					width: 12px;
					height: 1px;
					background-color: #999999;
					margin: 0 10px;
				}

				.handle_form_date {
					width: 187px;
					outline: none;
					font-size: 14px;
					font-family: Source Han Sans CN;
					font-weight: 400;
					line-height: 34px;
					color: #000000;
				}

				.handle_form_select {
					width: 128px;
					outline: none;
					font-size: 14px;
					font-family: Source Han Sans CN;
					font-weight: 400;
					line-height: 34px;
					color: #000000;
					margin-top: 9px;
				}

				.handle_form_upload {
					display: flex;
					flex-direction: column;

					.form_upload_box {
						display: flex;
						align-items: center;
						justify-content: center;
						width: 128px;
						line-height: 34px;
						background: #FFFFFF;
						border: 1px solid #EEEEEE;
						border-radius: 4px;
						font-size: 14px;
						font-family: Source Han Sans CN;
						font-weight: 400;
						color: #000000;
						cursor: pointer;

						.form_upload_icon {
							width: 14px;
							height: 14px;
							margin-right: 10px;
						}
					}

					.form_upload_tip {
						font-size: 12px;
						font-family: Source Han Sans CN;
						font-weight: 400;
						line-height: 17px;
						color: #999999;
						margin-top: 5px;
					}
				}
			}
		}

		.handle_form_btn {
			display: flex;
			align-items: center;
			margin: 40px 0 0px 0;

			.handle_form_cancel {
				margin-left: auto;
				width: 76px;
				line-height: 34px;
				background: #FFFFFF;
				border: 1px solid $theme_color;
				border-radius: 4px;
				font-size: 14px;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: $theme_color;
				text-align: center;
				cursor: pointer;
				margin-right: 16px;
			}

			.handle_form_confirm {
				width: 76px;
				line-height: 34px;
				background: $theme_color;
				border-radius: 4px;
				font-size: 14px;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #FFF;
				text-align: center;
				cursor: pointer;
			}
		}
	}
</style>
